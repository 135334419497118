import React, { useEffect, useRef, useState, useMemo } from "react";
import gif from './smartphoenix.gif';
import './App.css';
import Sunburst from "sunburst-chart";
import { Table, tableFilters } from "@itwin/itwinui-react";
import '@itwin/itwinui-variables';
import '@itwin/itwinui-css/global';

function App() {


  const translatedLabels = useMemo(
    () => ({
      filter: "Filter",
      clear: "Clear",
      from: "From",
      to: "To"
    }),
    []
  );

  const [style, setStyle] = useState("green");
  const handleSelect = useMemo(() => (selectedRows, tableState) => {

    console.log("onSelect is called when filtering");
    setStyle("orange");

  }, []);



  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    window.onresize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };
  }, []);

  window.onresize = () => {
    setWindowSize({
      width: window.innerWidth,
    });
  };


  let width;
  let height;
  if (windowSize.width < 100 && windowSize.width > 0) {
    width = 90;
    height = 90;
  } else if (windowSize.width < 480 && windowSize.width > 101) {
    width = 290;
    height = 290;
  } else if (windowSize.width < 1119 && windowSize.width > 481) {
    width = 470;
    height = 470;
  } else if (windowSize.width < 5000 && windowSize.width > 1120) {
    width = 750;
    height = 750;
  } else {
    width = 850;
    height = 850;
  }

  let sunburstLoaded = useRef(false);

  useEffect(() => {

    if (!sunburstLoaded.current) {

      sunburstLoaded.current = true;


      console.log('sun loaded');

      let data = {
        name: "NIST Cybersecurity Framework Core (CSF)",
        color: 'black',
        function: "Five Core Functions of the NIST CSF",
        categoryname: "23 Categories",
        categorydescription: "-",
        subcategorydescription: '108 Subcategories',
        children: [
          {
            name: "ID",
            color: 'dodgerblue',
            function: "IDENTIFY (ID)",
            categoryname: "Six Caategories",
            categorydescription: "-",
            subcategorydescription: '-',
            children: [
              {
                name: "ID.AM",
                color: '#0c3a66',
                function: "IDENTIFY (ID)",
                categoryname: "Asset Management (AM)",
                categorydescription: "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization’s risk strategy.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'ID.AM-1',
                    color: `orange`,
                    function: "IDENTIFY (ID)",
                    categoryname: "Asset Management (AM)",
                    categorydescription: "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization’s risk strategy.",
                    subcategorydescription: "Physical devices and systems within the organization are inventoried",
                    value: 100,
                  },
                  {
                    name: 'ID.AM-2',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Asset Management (AM)",
                    categorydescription: "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization’s risk strategy.",
                    subcategorydescription: "Software platforms and applications within the organization are inventoried",
                    value: 100,
                  },
                  {
                    name: 'ID.AM-3',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Asset Management (AM)",
                    categorydescription: "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization’s risk strategy.",
                    subcategorydescription: "Organizational communication and data flows are mapped.",
                    value: 100,
                  },
                  {
                    name: 'ID.AM-4',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Asset Management (AM)",
                    categorydescription: "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization’s risk strategy.",
                    subcategorydescription: "External information systems are catalogued.",
                    value: 100,
                  },
                  {
                    name: 'ID.AM-5',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Asset Management (AM)",
                    categorydescription: "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization’s risk strategy.",
                    subcategorydescription: "Resources (e.g., hardware, devices, data, time, personnel, and software) are prioritized based on their classification, criticality, and business value.",
                    value: 100,
                  },
                  {
                    name: 'ID.AM-6',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Asset Management (AM)",
                    categorydescription: "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization’s risk strategy.",
                    subcategorydescription: "Cybersecurity roles and responsibilities for the entire workforce and third-party stakeholders (e.g., suppliers, customers, partners) are established.",
                    value: 100,
                  }
                ]
              },
              {
                name: "ID.BE",
                color: '#0c3a66',
                function: "IDENTIFY (ID)",
                categoryname: "Business Environment (BE)",
                categorydescription: "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
                subcategorydescription: "n/a",
                children: [
                  {
                    name: 'ID.BE-1',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Business Environment (BE)",
                    categorydescription: "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
                    subcategorydescription: "The organization’s role in the supply chain is identified and communicated.",
                    value: 100,
                  },
                  {
                    name: 'ID.BE-2',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Business Environment (BE)",
                    categorydescription: "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
                    subcategorydescription: "The organization’s place in critical infrastructure and its industry sector is identified and communicated.",
                    value: 100,
                  },
                  {
                    name: 'ID.BE-3',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Business Environment (BE)",
                    categorydescription: "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
                    subcategorydescription: "Priorities for organizational mission, objectives, and activities are established and communicated.",
                    value: 100,
                  },
                  {
                    name: 'ID.BE-4',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Business Environment (BE)",
                    categorydescription: "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
                    subcategorydescription: "Dependencies and critical functions for delivery of critical services are established.",
                    value: 100,
                  },
                  {
                    name: 'ID.BE-5',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Business Environment (BE)",
                    categorydescription: "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
                    subcategorydescription: "Resilience requirements to support delivery of critical services are established for all operating states (e.g. under duress/attack, during recovery, normal operations).",
                    value: 100,
                  }
                ]
              },
              {
                name: "IV.GV",
                color: '#0c3a66',
                function: "IDENTIFY (ID)",
                categoryname: "Governance (GV)",
                categorydescription: "The policies, procedures, and processes to manage and monitor the organization’s regulatory, legal, risk, environmental, and operational requirements are understood and inform the management of cybersecurity risk.",
                subcategorydescription: "n/a",
                children: [
                  {
                    name: 'ID.GV-1',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Governance (GV)",
                    categorydescription: "The policies, procedures, and processes to manage and monitor the organization’s regulatory, legal, risk, environmental, and operational requirements are understood and inform the management of cybersecurity risk.",
                    subcategorydescription: "Organizational cybersecurity policy is established and communicated.",
                    value: 100,
                  },
                  {
                    name: 'ID.GV-2',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Governance (GV)",
                    categorydescription: "The policies, procedures, and processes to manage and monitor the organization’s regulatory, legal, risk, environmental, and operational requirements are understood and inform the management of cybersecurity risk.",
                    subcategorydescription: "Cybersecurity roles and responsibilities are coordinated and aligned with internal roles and external partners.",
                    value: 100,
                  },
                  {
                    name: 'ID.GV-3',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Governance (GV)",
                    categorydescription: "The policies, procedures, and processes to manage and monitor the organization’s regulatory, legal, risk, environmental, and operational requirements are understood and inform the management of cybersecurity risk.",
                    subcategorydescription: "Legal and regulatory requirements regarding cybersecurity, including privacy and civil liberties obligations, are understood and managed.",
                    value: 100,
                  },
                  {
                    name: 'ID.GV-4',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Governance (GV)",
                    categorydescription: "The policies, procedures, and processes to manage and monitor the organization’s regulatory, legal, risk, environmental, and operational requirements are understood and inform the management of cybersecurity risk.",
                    subcategorydescription: "Governance and risk management processes address cybersecurity risks.",
                    value: 100,
                  }
                ]
              },
              {
                name: "ID.RA",
                color: '#0c3a66',
                function: "IDENTIFY (ID)",
                categoryname: "Risk Assessment (RA)",
                categorydescription: "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
                categorydescription: "n/a",
                children: [
                  {
                    name: 'ID.RA-1',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Risk Assessment (RA)",
                    categorydescription: "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
                    subcategorydescription: "Asset vulnerabilities are identified and documented.",
                    value: 100,
                  },
                  {
                    name: 'ID.RA-2',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Risk Assessment (RA)",
                    categorydescription: "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
                    subcategorydescription: "Threats, both internal and external, are identified and documented.",
                    subcategorydescription: "Cyber threat intelligence is received from information sharing forums and sources.",
                    value: 100,
                  },
                  {
                    name: 'ID.RA-3',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Risk Assessment (RA)",
                    categorydescription: "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
                    subcategorydescription: "Threats, both internal and external, are identified and documented.",
                    value: 100,
                  },
                  {
                    name: 'ID.RA-4',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Risk Assessment (RA)",
                    categorydescription: "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
                    subcategorydescription: "Potential business impacts and likelihoods are identified.",
                    value: 100,
                  },
                  {
                    name: 'ID.RA-5',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Risk Assessment (RA)",
                    categorydescription: "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
                    subcategorydescription: "Threats, vulnerabilities, likelihoods, and impacts are used to determine risk.",
                    value: 100,
                  },
                  {
                    name: 'ID.RA-6',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Risk Assessment (RA)",
                    categorydescription: "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
                    subcategorydescription: "Risk responses are identified and prioritized.",
                    value: 100,
                  }
                ]
              },
              {
                name: "ID.RM",
                color: '#0c3a66',
                function: "IDENTIFY (ID)",
                categoryname: "Risk Management Strategy (RM)",
                categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support operational risk decisions.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'ID.RM-1',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Risk Management Strategy (RM)",
                    categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support operational risk decisions.",
                    subcategorydescription: "Risk management processes are established, managed, and agreed to by organizational stakeholders.",
                    value: 100,
                  },
                  {
                    name: 'ID.RM-2',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Risk Management Strategy (RM)",
                    categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support operational risk decisions.",
                    subcategorydescription: "Organizational risk tolerance is determined and clearly expressed.",
                    value: 100,
                  },
                  {
                    name: 'ID.RM-3',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Risk Management Strategy (RM)",
                    categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support operational risk decisions.",
                    subcategorydescription: "The organization’s determination of risk tolerance is informed by its role in critical infrastructure and sector specific risk analysis.",
                    value: 100,
                  }
                ]
              },
              {
                name: "ID.SC",
                color: '#0c3a66',
                function: "IDENTIFY (ID)",
                categoryname: "Supply Chain Risk Management (SC)",
                categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'ID.SC-1',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Supply Chain Risk Management (SC)",
                    categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
                    subcategorydescription: "Cyber supply chain risk management processes are identified, established, assessed, managed, and agreed to by organizational stakeholders.",
                    value: 100,
                  },
                  {
                    name: 'ID.SC-2',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Supply Chain Risk Management (SC)",
                    categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
                    subcategorydescription: "Suppliers and third party partners of information systems, components, and services are identified, prioritized, and assessed using a cyber supply chain risk assessment process.",
                    value: 100,
                  },
                  {
                    name: 'ID.SC-3',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Supply Chain Risk Management (SC)",
                    categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
                    subcategorydescription: "Contracts with suppliers and third-party partners are used to implement appropriate measures designed to meet the objectives of an organization’s cybersecurity program and Cyber Supply Chain Risk Management Plan.",
                    value: 100,
                  },
                  {
                    name: 'ID.SC-4',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Supply Chain Risk Management (SC)",
                    categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
                    subcategorydescription: "Suppliers and third-party partners are routinely assessed using audits, test results, or other forms of evaluations to confirm they are meeting their contractual obligations.",
                    value: 100,
                  },
                  {
                    name: 'ID.SC-5',
                    color: 'orange',
                    function: "IDENTIFY (ID)",
                    categoryname: "Supply Chain Risk Management (SC)",
                    categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
                    subcategorydescription: "Response and recovery planning and testing are conducted with suppliers and third-party providers.",
                    value: 100,
                  }
                ]
              }
            ]
          },
          {
            name: "PR",
            color: 'rebeccapurple',
            function: "PROTECT (PR)",
            categoryname: "Six Categories",
            categorydescription: "-",
            subcategorydescription: '-',
            children: [
              {
                name: "PR.AC",
                color: '#29143d',
                function: "PROTECT (PR)",
                categoryname: "Identity Management, Authentication and Access Control (AC)",
                categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
                subcategorydescription: "n/a",
                children: [
                  {
                    name: 'PR.AC-1',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Identity Management, Authentication and Access Control (AC)",
                    categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
                    subcategorydescription: "Identities and credentials are issued, managed, verified, revoked, and audited for authorized devices, users and processes.",
                    value: 100,
                  },
                  {
                    name: 'PR.AC-2',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Identity Management, Authentication and Access Control (AC)",
                    categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
                    subcategorydescription: "Physical access to assets is managed and protected.",
                    value: 100,
                  },
                  {
                    name: 'PR.AC-3',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Identity Management, Authentication and Access Control (AC)",
                    categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
                    subcategorydescription: "Remote access is managed.",
                    value: 100,
                  },
                  {
                    name: 'PR.AC-4',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Identity Management, Authentication and Access Control (AC)",
                    categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
                    subcategorydescription: "Access permissions and authorizations are managed, incorporating the principles of least privilege and separation of duties.",
                    value: 100,
                  },
                  {
                    name: 'PR.AC-5',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Identity Management, Authentication and Access Control (AC)",
                    categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
                    subcategorydescription: "Network integrity is protected (e.g., network segregation, network segmentation).",
                    value: 100,
                  },
                  {
                    name: 'PR.AC-6',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Identity Management, Authentication and Access Control (AC)",
                    categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
                    subcategorydescription: "Identities are proofed and bound to credentials and asserted in interactions.",
                    value: 100,
                  },
                  {
                    name: 'PR.AC-7',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Identity Management, Authentication and Access Control (AC)",
                    categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
                    subcategorydescription: "Users, devices, and other assets are authenticated (e.g., single-factor, multi-factor) commensurate with the risk of the transaction (e.g., individuals’ security and privacy risks and other organizational risks).",
                    value: 100,
                  }
                ]
              },
              {
                name: "PR.AT",
                color: '#29143d',
                function: "PROTECT (PR)",
                categoryname: "Awareness and Training (AT)",
                categorydescription: "The organization’s personnel and partners are provided cybersecurity awareness education and are trained to perform their cybersecurity-related duties and responsibilities consistent with related policies, procedures, and agreements.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'PR.AT-1',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Awareness and Training (AT)",
                    categorydescription: "The organization’s personnel and partners are provided cybersecurity awareness education and are trained to perform their cybersecurity-related duties and responsibilities consistent with related policies, procedures, and agreements.",
                    subcategorydescription: "All users are informed and trained.",
                    value: 100,
                  },
                  {
                    name: 'PR.AT-2',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Awareness and Training (AT)",
                    categorydescription: "The organization’s personnel and partners are provided cybersecurity awareness education and are trained to perform their cybersecurity-related duties and responsibilities consistent with related policies, procedures, and agreements.",
                    subcategorydescription: "Privileged users understand their roles and responsibilities.",
                    value: 100,
                  },
                  {
                    name: 'PR.AT-3',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Awareness and Training (AT)",
                    categorydescription: "The organization’s personnel and partners are provided cybersecurity awareness education and are trained to perform their cybersecurity-related duties and responsibilities consistent with related policies, procedures, and agreements.",
                    subcategorydescription: "Third-party stakeholders (e.g., suppliers, customers, partners) understand their roles and responsibilities.",
                    value: 100,
                  },
                  {
                    name: 'PR.AT-4',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Awareness and Training (AT)",
                    categorydescription: "The organization’s personnel and partners are provided cybersecurity awareness education and are trained to perform their cybersecurity-related duties and responsibilities consistent with related policies, procedures, and agreements.",
                    subcategorydescription: "Senior executives understand their roles and responsibilities.",
                    value: 100,
                  },
                  {
                    name: 'PR.AT-5',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Awareness and Training (AT)",
                    categorydescription: "The organization’s personnel and partners are provided cybersecurity awareness education and are trained to perform their cybersecurity-related duties and responsibilities consistent with related policies, procedures, and agreements.",
                    subcategorydescription: "Physical and cybersecurity personnel understand their roles and responsibilities.",
                    value: 100,
                  }
                ]
              },
              {
                name: "PR.DS",
                color: '#29143d',
                function: "PROTECT (PR)",
                categoryname: "Data Security (DS)",
                categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'PR.DS-1',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Data Security (DS)",
                    categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
                    subcategorydescription: "Data-at-rest is protected.",
                    value: 100,
                  },
                  {
                    name: 'PR.DS-2',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Data Security (DS)",
                    categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
                    subcategorydescription: "Data-in-transit is protected.",
                    value: 100,
                  },
                  {
                    name: 'PR.DS-3',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Data Security (DS)",
                    categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
                    subcategorydescription: "Assets are formally managed throughout removal, transfers, and disposition.",
                    value: 100,
                  },
                  {
                    name: 'PR.DS-4',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Data Security (DS)",
                    categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
                    subcategorydescription: "Adequate capacity to ensure availability is maintained.",
                    value: 100,
                  },
                  {
                    name: 'PR.DS-5',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Data Security (DS)",
                    categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
                    subcategorydescription: "Protections against data leaks are implemented.",
                    value: 100,
                  },
                  {
                    name: 'PR.DS-6',
                    color: '#39FF14',
                    function: "PROTECT (PR)",
                    categoryname: "Data Security (DS)",
                    categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
                    subcategorydescription: "Integrity checking mechanisms are used to verify software, firmware, and information integrity.",
                    value: 100,
                  },
                  {
                    name: 'PR.DS-7',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Data Security (DS)",
                    categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
                    subcategorydescription: "The development and testing environment(s) are separate from the production environment.",
                    value: 100,
                  },
                  {
                    name: 'PR.DS-8',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Data Security (DS)",
                    categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
                    subcategorydescription: "Integrity checking mechanisms are used to verify hardware integrity.",
                    value: 100,
                  }
                ]
              },
              {
                name: "PR.IP",
                color: '#29143d',
                function: "PROTECT (PR)",
                categoryname: "Information Protection Processes and Procedures (IP)",
                categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'PR.IP-1',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Information Protection Processes and Procedures (IP)",
                    categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
                    subcategorydescription: "A baseline configuration of information technology/industrial control systems is created and maintained incorporating security principles (e.g. concept of least functionality).",
                    value: 100,
                  },
                  {
                    name: 'PR.IP-2',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Information Protection Processes and Procedures (IP)",
                    categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
                    subcategorydescription: "A System Development Life Cycle to manage systems is implemented.",
                    value: 100,
                  },
                  {
                    name: 'PR.IP-3',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Information Protection Processes and Procedures (IP)",
                    categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
                    subcategorydescription: "Configuration change control processes are in place.",
                    value: 100,
                  },
                  {
                    name: 'PR.IP-4',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Information Protection Processes and Procedures (IP)",
                    categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
                    subcategorydescription: "Backups of information are conducted, maintained, and tested.",
                    value: 100,
                  },
                  {
                    name: 'PR.IP-5',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Information Protection Processes and Procedures (IP)",
                    categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
                    subcategorydescription: "Policy and regulations regarding the physical operating environment for organizational assets are met.",
                    value: 100,
                  },
                  {
                    name: 'PR.IP-6',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Information Protection Processes and Procedures (IP)",
                    categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
                    subcategorydescription: "Data is destroyed according to policy.",
                    value: 100,
                  },
                  {
                    name: 'PR.IP-7',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Information Protection Processes and Procedures (IP)",
                    categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
                    subcategorydescription: "Protection processes are improved.",
                    value: 100,
                  },
                  {
                    name: 'PR.IP-8',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Information Protection Processes and Procedures (IP)",
                    categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
                    subcategorydescription: "Effectiveness of protection technologies is shared.",
                    value: 100,
                  },
                  {
                    name: 'PR.IP-9',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Information Protection Processes and Procedures (IP)",
                    categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
                    subcategorydescription: "Response plans (Incident Response and Business Continuity) and recovery plans (Incident Recovery and Disaster Recovery) are in place and managed.",
                    value: 100,
                  },
                  {
                    name: 'PR.IP-10',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Information Protection Processes and Procedures (IP)",
                    categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
                    subcategorydescription: "Response and recovery plans are tested.",
                    value: 100,
                  },
                  {
                    name: 'PR.IP-11',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Information Protection Processes and Procedures (IP)",
                    categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
                    subcategorydescription: "Cybersecurity is included in human resources practices (e.g., deprovisioning, personnel screening).",
                    value: 100,
                  },
                  {
                    name: 'PR.IP-12',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Information Protection Processes and Procedures (IP)",
                    categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
                    subcategorydescription: "A vulnerability management plan is developed and implemented.",
                    value: 100,
                  }
                ]
              },
              {
                name: "PR.MA",
                color: '#29143d',
                function: "PROTECT (PR)",
                categoryname: "Maintenance (MA)",
                categorydescription: "Maintenance and repairs of industrial control and information system components are performed consistent with policies and procedures.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'PR.MA-1',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Maintenance (MA)",
                    categorydescription: "Maintenance and repairs of industrial control and information system components are performed consistent with policies and procedures.",
                    subcategorydescription: "Maintenance and repair of organizational assets are performed and logged, with approved and controlled tools.",
                    value: 100,
                  },
                  {
                    name: 'PR.MA-2',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Maintenance (MA)",
                    categorydescription: "Maintenance and repairs of industrial control and information system components are performed consistent with policies and procedures.",
                    subcategorydescription: "Remote maintenance of organizational assets is approved, logged, and performed in a manner that prevents unauthorized access.",
                    value: 100,
                  }
                ]
              },
              {
                name: "PR.PT",
                color: '#29143d',
                function: "PROTECT (PR)",
                categoryname: "Protective Technology (PT)",
                categorydescription: "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'PR.PT-1',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Protective Technology (PT)",
                    categorydescription: "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
                    subcategorydescription: "Audit/log records are determined, documented, implemented, and reviewed in accordance with policy.",
                    value: 100,
                  },
                  {
                    name: 'PR.PT-2',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Protective Technology (PT)",
                    categorydescription: "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
                    subcategorydescription: "Removable media is protected and its use restricted according to policy.",
                    value: 100,
                  },
                  {
                    name: 'PR.PT-3',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Protective Technology (PT)",
                    categorydescription: "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
                    subcategorydescription: "The principle of least functionality is incorporated by configuring systems to provide only essential capabilities.",
                    value: 100,
                  },
                  {
                    name: 'PR.PT-4',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Protective Technology (PT)",
                    categorydescription: "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
                    subcategorydescription: "Communications and control networks are protected.",
                    value: 100,
                  },
                  {
                    name: 'PR.PT-5',
                    color: 'orange',
                    function: "PROTECT (PR)",
                    categoryname: "Protective Technology (PT)",
                    categorydescription: "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
                    subcategorydescription: "Mechanisms (e.g., failsafe, load balancing, hot swap) are implemented to achieve resilience requirements in normal and adverse situations.",
                    value: 100,
                  }
                ]
              }
            ]
          },
          {
            name: "DE",
            color: 'DarkGoldenRod',
            function: "DETECT (DE)",
            categoryname: "Three Categories",
            categorydescription: "-",
            subcategorydescription: '-',
            children: [
              {
                name: "DE.AE",
                color: '#4a3604',
                function: "DETECT (DE)",
                categoryname: "Anomalies and Events (AE)",
                categorydescription: "Anomalous activity is detected and the potential impact of events is understood.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'DE.AE-1',
                    color: '#39FF14',
                    function: "DETECT (DE)",
                    categoryname: "Anomalies and Events (AE)",
                    categorydescription: "Anomalous activity is detected and the potential impact of events is understood.",
                    subcategorydescription: "A baseline of network operations and expected data flows for users and systems is established and managed.",
                    value: 100,
                  },
                  {
                    name: 'DE.AE-2',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Anomalies and Events (AE)",
                    categorydescription: "Anomalous activity is detected and the potential impact of events is understood.",
                    subcategorydescription: "Detected events are analyzed to understand attack targets and methods.",
                    value: 100,
                  },
                  {
                    name: 'DE.AE-3',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Anomalies and Events (AE)",
                    categorydescription: "Anomalous activity is detected and the potential impact of events is understood.",
                    subcategorydescription: "Event data are collected and correlated from multiple sources and sensors.",
                    value: 100,
                  },
                  {
                    name: 'DE.AE-4',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Anomalies and Events (AE)",
                    categorydescription: "Anomalous activity is detected and the potential impact of events is understood.",
                    subcategorydescription: "Impact of events is determined.",
                    value: 100,
                  },
                  {
                    name: 'DE.AE-5',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Anomalies and Events (AE)",
                    categorydescription: "Anomalous activity is detected and the potential impact of events is understood.",
                    subcategorydescription: "Incident alert thresholds are established.",
                    value: 100,
                  }
                ]
              },
              {
                name: "DE.CM",
                color: '#4a3604',
                function: "DETECT (DE)",
                categoryname: "Security Continuous Monitoring (CM)",
                categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'DE.CM-1',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Security Continuous Monitoring (CM)",
                    categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
                    subcategorydescription: "The network is monitored to detect potential cybersecurity events.",
                    value: 100,
                  },
                  {
                    name: 'DE.CM-2',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Security Continuous Monitoring (CM)",
                    categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
                    subcategorydescription: "The physical environment is monitored to detect potential cybersecurity events.",
                    value: 100,
                  },
                  {
                    name: 'DE.CM-3',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Security Continuous Monitoring (CM)",
                    categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
                    subcategorydescription: "Personnel activity is monitored to detect potential cybersecurity events.",
                    value: 100,
                  },
                  {
                    name: 'DE.CM-4',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Security Continuous Monitoring (CM)",
                    categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
                    subcategorydescription: "Malicious code is detected.",
                    value: 100,
                  },
                  {
                    name: 'DE.CM-5',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Security Continuous Monitoring (CM)",
                    categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
                    subcategorydescription: "Unauthorized mobile code is detected.",
                    value: 100,
                  },
                  {
                    name: 'DE.CM-6',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Security Continuous Monitoring (CM)",
                    categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
                    subcategorydescription: "External service provider activity is monitored to detect potential cybersecurity events.",
                    value: 100,
                  },
                  {
                    name: 'DE.CM-7',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Security Continuous Monitoring (CM)",
                    categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
                    subcategorydescription: "Monitoring for unauthorized personnel, connections, devices, and software is performed.",
                    value: 100,
                  },
                  {
                    name: 'DE.CM-8',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Security Continuous Monitoring (CM)",
                    categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
                    subcategorydescription: "Vulnerability scans are performed.",
                    value: 100,
                  }
                ]
              },
              {
                name: "DE.DP",
                color: '#4a3604',
                function: "DETECT (DE)",
                categoryname: "Detection Processes (DP)",
                categorydescription: "Detection processes and procedures are maintained and tested to ensure awareness of anomalous events.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'DE.DP-1',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Detection Processes (DP)",
                    categorydescription: "Detection processes and procedures are maintained and tested to ensure awareness of anomalous events.",
                    subcategorydescription: "Roles and responsibilities for detection are well defined to ensure accountability.",
                    value: 100,
                  },
                  {
                    name: 'DE.DP-2',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Detection Processes (DP)",
                    categorydescription: "Detection processes and procedures are maintained and tested to ensure awareness of anomalous events.",
                    subcategorydescription: "Detection activities comply with all applicable requirements.",
                    value: 100,
                  },
                  {
                    name: 'DE.DP-3',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Detection Processes (DP)",
                    categorydescription: "Detection processes and procedures are maintained and tested to ensure awareness of anomalous events.",
                    subcategorydescription: "Detection processes are tested.",
                    value: 100,
                  },
                  {
                    name: 'DE.DP-4',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Detection Processes (DP)",
                    categorydescription: "Detection processes and procedures are maintained and tested to ensure awareness of anomalous events.",
                    subcategorydescription: "Event detection information is communicated.",
                    value: 100,
                  },
                  {
                    name: 'DE.DP-5',
                    color: 'orange',
                    function: "DETECT (DE)",
                    categoryname: "Detection Processes (DP)",
                    categorydescription: "Detection processes and procedures are maintained and tested to ensure awareness of anomalous events.",
                    subcategorydescription: "Detection processes are continuously improved.",
                    value: 100,
                  }
                ]
              }
            ]
          },
          {
            name: "RS",
            color: 'crimson',
            function: "RESPOND (RS)",
            categoryname: "Five Categories",
            categorydescription: "-",
            subcategorydescription: '-',
            children: [
              {
                name: "RS.RP",
                color: '#580818',
                function: "RESPOND (RS)",
                categoryname: "Response Planning (RP)",
                categorydescription: "Response processes and procedures are executed and maintained, to ensure response to detected cybersecurity incidents.",
                subcategorydescription: "n/a",
                children: [
                  {
                    name: 'RS.RP-1',
                    color: '#39FF14',
                    function: "RESPOND (RS)",
                    categoryname: "Response Planning (RP)",
                    categorydescription: "Response processes and procedures are executed and maintained, to ensure response to detected cybersecurity incidents.",
                    subcategorydescription: "Response plan is executed during or after an incident.",
                    value: 100,
                  }
                ]
              },
              {
                name: "RS.CO",
                color: '#580818',
                function: "RESPOND (RS)",
                categoryname: "Communications (CO)",
                categorydescription: "Response activities are coordinated with internal and external stakeholders (e.g. external support from law enforcement agencies).",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'RS.CO-1',
                    color: 'orange',
                    function: "RESPOND (RS)",
                    categoryname: "Communications (CO)",
                    categorydescription: "Response activities are coordinated with internal and external stakeholders (e.g. external support from law enforcement agencies).",
                    subcategorydescription: "Personnel know their roles and order of operations when a response is needed.",
                    value: 100,
                  },
                  {
                    name: 'RS.CO-2',
                    color: 'orange',
                    function: "RESPOND (RS)",
                    categoryname: "Communications (CO)",
                    categorydescription: "Response activities are coordinated with internal and external stakeholders (e.g. external support from law enforcement agencies).",
                    subcategorydescription: "Incidents are reported consistent with established criteria.",
                    value: 100,
                  },
                  {
                    name: 'RS.CO-3',
                    color: 'orange',
                    function: "RESPOND (RS)",
                    categoryname: "Communications (CO)",
                    categorydescription: "Response activities are coordinated with internal and external stakeholders (e.g. external support from law enforcement agencies).",
                    subcategorydescription: "Information is shared consistent with response plans.",
                    value: 100,
                  },
                  {
                    name: 'RS.CO-4',
                    color: 'orange',
                    function: "RESPOND (RS)",
                    categoryname: "Communications (CO)",
                    categorydescription: "Response activities are coordinated with internal and external stakeholders (e.g. external support from law enforcement agencies).",
                    subcategorydescription: "Coordination with stakeholders occurs consistent with response plans.",
                    value: 100,
                  },
                  {
                    name: 'RS.CO-5',
                    color: '#39FF14',
                    function: "RESPOND (RS)",
                    categoryname: "Communications (CO)",
                    categorydescription: "Response activities are coordinated with internal and external stakeholders (e.g. external support from law enforcement agencies).",
                    subcategorydescription: "Voluntary information sharing occurs with external stakeholders to achieve broader cybersecurity situational awareness.",
                    value: 100,
                  }
                ]
              },
              {
                name: "RS.AN",
                color: '#580818',
                function: "RESPOND (RS)",
                categoryname: "Analysis (AN)",
                categorydescription: "Analysis is conducted to ensure effective response and support recovery activities.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'RS.AN-1',
                    color: '#39FF14',
                    function: "RESPOND (RS)",
                    categoryname: "Analysis (AN)",
                    categorydescription: "Analysis is conducted to ensure effective response and support recovery activities.",
                    subcategorydescription: "Notifications from detection systems are investigated.",
                    value: 100,
                  },
                  {
                    name: 'RS.AN-2',
                    color: 'orange',
                    function: "RESPOND (RS)",
                    categoryname: "Analysis (AN)",
                    categorydescription: "Analysis is conducted to ensure effective response and support recovery activities.",
                    subcategorydescription: "The impact of the incident is understood.",
                    value: 100,
                  },
                  {
                    name: 'RS.AN-3',
                    color: 'orange',
                    function: "RESPOND (RS)",
                    categoryname: "Analysis (AN)",
                    categorydescription: "Analysis is conducted to ensure effective response and support recovery activities.",
                    subcategorydescription: "Forensics are performed.",
                    value: 100,
                  },
                  {
                    name: 'RS.AN-4',
                    color: 'orange',
                    function: "RESPOND (RS)",
                    categoryname: "Analysis (AN)",
                    categorydescription: "Analysis is conducted to ensure effective response and support recovery activities.",
                    subcategorydescription: "Incidents are categorized consistent with response plans.",
                    value: 100,
                  },
                  {
                    name: 'RS.AN-5',
                    color: 'orange',
                    function: "RESPOND (RS)",
                    categoryname: "Analysis (AN)",
                    categorydescription: "Analysis is conducted to ensure effective response and support recovery activities.",
                    subcategorydescription: "Processes are established to receive, analyze and respond to vulnerabilities disclosed to the organization from internal and external sources.",
                    value: 100,
                  }
                ]
              },
              {
                name: "RS.MI",
                color: '#580818',
                function: "RESPOND (RS)",
                categoryname: "Mitigation (MI)",
                categorydescription: "Activities are performed to prevent expansion of an event, mitigate its effects, and resolve the incident.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'RS.MI-1',
                    color: 'orange',
                    function: "RESPOND (RS)",
                    categoryname: "Mitigation (MI)",
                    categorydescription: "Activities are performed to prevent expansion of an event, mitigate its effects, and resolve the incident.",
                    subcategorydescription: "Incidents are contained.",
                    value: 100,
                  },
                  {
                    name: 'RS.MI-2',
                    color: 'orange',
                    function: "RESPOND (RS)",
                    categoryname: "Mitigation (MI)",
                    categorydescription: "Activities are performed to prevent expansion of an event, mitigate its effects, and resolve the incident.",
                    subcategorydescription: "Incidents are mitigated.",
                    value: 100,
                  },
                  {
                    name: 'RS.MI-3',
                    color: 'orange',
                    function: "RESPOND (RS)",
                    categoryname: "Mitigation (MI)",
                    categorydescription: "Activities are performed to prevent expansion of an event, mitigate its effects, and resolve the incident.",
                    subcategorydescription: "Newly identified vulnerabilities are mitigated or documented as accepted risks.",
                    value: 100,
                  }
                ]
              },
              {
                name: "RS.IM",
                color: '#580818',
                function: "RESPOND (RS)",
                categoryname: "Improvements (IM)",
                categorydescription: "Organizational response activities are improved by incorporating lessons learned from current and previous detection/response activities.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'RS.IM-1',
                    color: 'orange',
                    function: "RESPOND (RS)",
                    categoryname: "Improvements (IM)",
                    categorydescription: "Organizational response activities are improved by incorporating lessons learned from current and previous detection/response activities.",
                    subcategorydescription: "Response plans incorporate lessons learned.",
                    value: 100,
                  },
                  {
                    name: 'RS.IM-2',
                    color: 'orange',
                    function: "RESPOND (RS)",
                    categoryname: "Improvements (IM)",
                    categorydescription: "Organizational response activities are improved by incorporating lessons learned from current and previous detection/response activities.",
                    subcategorydescription: "Response strategies are updated.",
                    value: 100,
                  }
                ]
              }
            ]
          },
          {
            name: "RC",
            color: 'SeaGreen',
            function: "RECOVER (RC)",
            categoryname: "Three Categories",
            categorydescription: "-",
            subcategorydescription: '-',
            children: [
              {
                name: "RC.RP",
                color: '#123823',
                function: "RECOVER (RC)",
                categoryname: "Recovery Planning (RP)",
                categorydescription: "Recovery processes and procedures are executed and maintained to ensure restoration of systems or assets affected by cybersecurity incidents.",
                subcategorydescription: "n/a",
                children: [
                  {
                    name: 'RC.RP-1',
                    color: 'orange',
                    function: "RECOVER (RC)",
                    categoryname: "Recovery Planning (RP)",
                    categorydescription: "Recovery processes and procedures are executed and maintained to ensure restoration of systems or assets affected by cybersecurity incidents.",
                    subcategorydescription: "Recovery plan is executed during or after a cybersecurity incident.",
                    value: 100,
                  }
                ]
              },
              {
                name: "RC.IM",
                color: '#123823',
                function: "RECOVER (RC)",
                categoryname: "Improvements (IM)",
                categorydescription: "Recovery planning and processes are improved by incorporating lessons learned into future activities.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'RC.IM-1',
                    color: 'orange',
                    function: "RECOVER (RC)",
                    categoryname: "Improvements (IM)",
                    categorydescription: "Recovery planning and processes are improved by incorporating lessons learned into future activities.",
                    subcategorydescription: "Recovery plans incorporate lessons learned.",
                    value: 100,
                  },
                  {
                    name: 'RC.IM-2',
                    color: 'orange',
                    function: "RECOVER (RC)",
                    categoryname: "Improvements (IM)",
                    categorydescription: "Recovery planning and processes are improved by incorporating lessons learned into future activities.",
                    subcategorydescription: "Recovery strategies are updated.",
                    value: 100,
                  }
                ]
              },
              {
                name: "RC.CO",
                color: '#123823',
                function: "RECOVER (RC)",
                categoryname: "Communications (CO)",
                categorydescription: "Restoration activities are coordinated with internal and external parties (e.g. coordinating centers, Internet Service Providers, owners of attacking systems, victims, other CSIRTs, and vendors.",
                subcategorydescription: 'n/a',
                children: [
                  {
                    name: 'RC.CO-1',
                    color: 'orange',
                    function: "RECOVER (RC)",
                    categoryname: "Communications (CO)",
                    categorydescription: "Restoration activities are coordinated with internal and external parties (e.g. coordinating centers, Internet Service Providers, owners of attacking systems, victims, other CSIRTs, and vendors.",
                    subcategorydescription: "Public relations are managed.",
                    value: 100,
                  },
                  {
                    name: 'RC.CO-2',
                    color: 'orange',
                    function: "RECOVER (RC)",
                    categoryname: "Communications (CO)",
                    categorydescription: "Restoration activities are coordinated with internal and external parties (e.g. coordinating centers, Internet Service Providers, owners of attacking systems, victims, other CSIRTs, and vendors.",
                    subcategorydescription: "Reputation is repaired after an incident.",
                    value: 100,
                  },
                  {
                    name: 'RC.CO-3',
                    color: 'orange',
                    function: "RECOVER (RC)",
                    categoryname: "Communications (CO)",
                    categorydescription: "Restoration activities are coordinated with internal and external parties (e.g. coordinating centers, Internet Service Providers, owners of attacking systems, victims, other CSIRTs, and vendors.",
                    subcategorydescription: "Recovery activities are communicated to internal and external stakeholders as well as executive and management teams.",
                    value: 100,
                  }
                ]
              }
            ]
          }
        ]
      }

      Sunburst()
        .data(data)
        .width(width)
        .height(height)
        .size("value")
        .color((data) => `${data.color}`)
        .minSliceAngle(.4)
        .excludeRoot(true)
        .transitionDuration(1000)
        .centerRadius(0.09)
        .radiusScaleExponent(0.5)
        .tooltipContent((d, node) => `<b><u>Function</u>:</b> ${node.data.function}<br/><b><u>Category Name</u>:</b> ${node.data.categoryname}<br/><b><u>Category Description</u>:</b> ${node.data.categorydescription}<br/><b><u>Subcategory Description</u>:</b> ${node.data.subcategorydescription}`)
        // eslint-disable-next-line
        (document.getElementById("mychart"));
    }

  }, [width]);

  const columns = useMemo(() => [
    {
      Header: "NIST Cybersecurity Framework Core",
      columns: [
        {
          Filter: tableFilters.TextFilter(translatedLabels),
          Header: "Funtion", accessor: "function",
          fieldType: "text",
          id: "function",
          minWidth: 105
        },
        {
          Filter: tableFilters.TextFilter(translatedLabels),
          Header: "Category\nID",
          accessor: "categoryid",
          fieldType: "text",
          id: "categoryid",
          minWidth: 115
        },
        {
          Filter: tableFilters.TextFilter(translatedLabels),
          Header: "Category\nName",
          accessor: "categoryname",
          fieldType: "text",
          id: "categoryname",
          minWidth: 115
        },
        {
          Filter: tableFilters.TextFilter(translatedLabels),
          Header: "Category\nDescription",
          accessor: "categorydescription",
          fieldType: "text",
          id: "categorydescription",
          minWidth: 200
        },
        {
          Filter: tableFilters.TextFilter(translatedLabels),
          Header: "Subcategory\nID",
          accessor: "subcategoryid",
          fieldType: "text",
          id: "subcategoryid",
          minWidth: 130
        },
        {
          Filter: tableFilters.TextFilter(translatedLabels),
          Header: "Subcategory\nDescription",
          accessor: "subcategorydescription",
          fieldType: "text",
          id: "subcategorydescription",
          minWidth: 150
        },
        {
          Filter: tableFilters.TextFilter(translatedLabels),
          Header: "Information\nReferences",
          accessor: "informationreferences",
          fieldType: "text",
          id: "informationreferences",
          minWidth: 200
        }
      ]
    }
  ],
    [translatedLabels]
  );

  const dataT = useMemo(() => [
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.AM",
      categoryname: "Asset Management",
      categorydescription: "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization’s risk strategy.",
      subcategoryid: "ID.AM-1",
      subcategorydescription: "Physical devices and systems within the organization are inventoried",
      informationreferences: "CIS CSC 1; COBIT 5 BAI09.01, BAI09.02; ISA 62443-2-1:2009 4.2.3.4; ISA 62443-3-3:2013 SR 7.8; ISO/IEC 27001:2013 A.8.1.1, A.8.1.2; NIST SP 800-53 Rev. 4 CM-8, PM-5"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.AM",
      categoryname: "Asset Management",
      categorydescription: "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization’s risk strategy.",
      subcategoryid: "ID.AM-2",
      subcategorydescription: "Software platforms and applications within the organization are inventoried",
      informationreferences: "CIS CSC 2; COBIT 5 BAI09.01, BAI09.02, BAI09.05; ISA 62443-2-1:2009 4.2.3.4; ISA 62443-3-3:2013 SR 7.8; ISO/IEC 27001:2013 A.8.1.1, A.8.1.2, A.12.5.1; NIST SP 800-53 Rev. 4 CM-8, PM-5"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.AM",
      categoryname: "Asset Management",
      categorydescription: "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization’s risk strategy.",
      subcategoryid: "ID.AM-3",
      subcategorydescription: "Organizational communication and data flows are mapped.",
      informationreferences: "CIS CSC 12; COBIT 5 DSS05.02; ISA 62443-2-1:2009 4.2.3.4; ISO/IEC 27001:2013 A.13.2.1, A.13.2.2; NIST SP 800-53 Rev. 4 AC-4, CA-3, CA-9, PL-8"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.AM",
      categoryname: "Asset Management",
      categorydescription: "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization’s risk strategy.",
      subcategoryid: "ID.AM-4",
      subcategorydescription: "External information systems are catalogued.",
      informationreferences: "CIS CSC 12; COBIT 5 APO02.02, APO10.04, DSS01.02; ISO/IEC 27001:2013 A.11.2.6; NIST SP 800-53 Rev. 4 AC-20, SA-9"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.AM",
      categoryname: "Asset Management",
      categorydescription: "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization’s risk strategy.",
      subcategoryid: "ID.AM-5",
      subcategorydescription: "Resources (e.g., hardware, devices, data, time, personnel, and software) are prioritized based on their classification, criticality, and business value.",
      informationreferences: "CIS CSC 13, 14; COBIT 5 APO03.03, APO03.04, APO12.01, BAI04.02, BAI09.02; ISA 62443-2-1:2009 4.2.3.6; ISO/IEC 27001:2013 A.8.2.1; NIST SP 800-53 Rev. 4 CP-2, RA-2, SA-14, SC-6"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.AM",
      categoryname: "Asset Management",
      categorydescription: "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization’s risk strategy.",
      subcategoryid: "ID.AM-6",
      subcategorydescription: "Cybersecurity roles and responsibilities for the entire workforce and third-party stakeholders (e.g., suppliers, customers, partners) are established.",
      informationreferences: "CIS CSC 17, 19; COBIT 5 APO01.02, APO07.06, APO13.01, DSS06.03; ISA 62443-2-1:2009 4.3.2.3.3; ISO/IEC 27001:2013 A.6.1.1; NIST SP 800-53 Rev. 4 CP-2, PS-7, PM-11"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.BE",
      categoryname: "Business Environment",
      categorydescription: "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
      subcategoryid: "ID.BE-1",
      subcategorydescription: "The organization’s role in the supply chain is identified and communicated.",
      informationreferences: "COBIT 5 APO08.01, APO08.04, APO08.05, APO10.03, APO10.04, APO10.05; ISO/IEC 27001:2013 A.15.1.1, A.15.1.2, A.15.1.3, A.15.2.1, A.15.2.2; NIST SP 800-53 Rev. 4 CP-2, SA-12"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.BE",
      categoryname: "Business Environment",
      categorydescription: "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
      subcategoryid: "ID.BE-2",
      subcategorydescription: "The organization’s place in critical infrastructure and its industry sector is identified and communicated.",
      informationreferences: "COBIT 5 APO02.06, APO03.01; ISO/IEC 27001:2013 Clause 4.1; NIST SP 800-53 Rev. 4 PM-8"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.BE",
      categoryname: "Business Environment",
      categorydescription: "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
      subcategoryid: "ID.BE-3",
      subcategorydescription: "Priorities for organizational mission, objectives, and activities are established and communicated.",
      informationreferences: "COBIT 5 APO02.01, APO02.06, APO03.01; ISA 62443-2-1:2009 4.2.2.1, 4.2.3.6; NIST SP 800-53 Rev. 4 PM-11, SA-14"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.BE",
      categoryname: "Business Environment",
      categorydescription: "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
      subcategoryid: "ID.BE-4",
      subcategorydescription: "Dependencies and critical functions for delivery of critical services are established.",
      informationreferences: "COBIT 5 APO10.01, BAI04.02, BAI09.02; ISO/IEC 27001:2013 A.11.2.2, A.11.2.3, A.12.1.3; NIST SP 800-53 Rev. 4 CP-8, PE-9, PE-11, PM-8, SA-14"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.BE",
      categoryname: "Business Environment",
      categorydescription: "The organization’s mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
      subcategoryid: "ID.BE-5",
      subcategorydescription: "Resilience requirements to support delivery of critical services are established for all operating states (e.g. under duress/attack, during recovery, normal operations).",
      informationreferences: "COBIT 5 BAI03.02, DSS04.02; ISO/IEC 27001:2013 A.11.1.4, A.17.1.1, A.17.1.2, A.17.2.1; NIST SP 800-53 Rev. 4 CP-2, CP-11, SA-13, SA-14"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.GV",
      categoryname: "Governance",
      categorydescription: "The policies, procedures, and processes to manage and monitor the organization’s regulatory, legal, risk, environmental, and operational requirements are understood and inform the management of cybersecurity risk.",
      subcategoryid: "ID.GV-1",
      subcategorydescription: "Organizational cybersecurity policy is established and communicated.",
      informationreferences: "CIS CSC 19; COBIT 5 APO01.03, APO13.01, EDM01.01, EDM01.02; ISA 62443-2-1:2009 4.3.2.6; ISO/IEC 27001:2013 A.5.1.1; NIST SP 800-53 Rev. 4 -1 controls from all security control families"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.GV",
      categoryname: "Governance",
      categorydescription: "The policies, procedures, and processes to manage and monitor the organization’s regulatory, legal, risk, environmental, and operational requirements are understood and inform the management of cybersecurity risk.",
      subcategoryid: "ID.GV-2",
      subcategorydescription: "Cybersecurity roles and responsibilities are coordinated and aligned with internal roles and external partners.",
      informationreferences: "CIS CSC 19; COBIT 5 APO01.02, APO10.03, APO13.02, DSS05.04; ISA 62443-2-1:2009 4.3.2.3.3; ISO/IEC 27001:2013 A.6.1.1, A.7.2.1, A.15.1.1; NIST SP 800-53 Rev. 4 PS-7, PM-1, PM-2"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.GV",
      categoryname: "Governance",
      categorydescription: "The policies, procedures, and processes to manage and monitor the organization’s regulatory, legal, risk, environmental, and operational requirements are understood and inform the management of cybersecurity risk.",
      subcategoryid: "ID.GV-3",
      subcategorydescription: "Legal and regulatory requirements regarding cybersecurity, including privacy and civil liberties obligations, are understood and managed.",
      informationreferences: "CIS CSC 19; COBIT 5 BAI02.01, MEA03.01, MEA03.04; ISA 62443-2-1:2009 4.4.3.7; ISO/IEC 27001:2013 A.18.1.1, A.18.1.2, A.18.1.3, A.18.1.4, A.18.1.5; NIST SP 800-53 Rev. 4-1 controls from all security control families"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.GV",
      categoryname: "Governance",
      categorydescription: "The policies, procedures, and processes to manage and monitor the organization’s regulatory, legal, risk, environmental, and operational requirements are understood and inform the management of cybersecurity risk.",
      subcategoryid: "ID.GV-4",
      subcategorydescription: "Governance and risk management processes address cybersecurity risks.",
      informationreferences: "COBIT 5 EDM03.02, APO12.02, APO12.05, DSS04.02; ISA 62443-2-1:2009 4.2.3.1, 4.2.3.3, 4.2.3.8, 4.2.3.9, 4.2.3.11, 4.3.2.4.3, 4.3.2.6.3; ISO/IEC 27001:2013 Clause 6; NIST SP 800-53 Rev. 4 SA-2, PM-3, PM-7, PM-9, PM-10, PM-11"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.RA",
      categoryname: "Risk Assessment",
      categorydescription: "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
      subcategoryid: "ID.RA-1",
      subcategorydescription: "Asset vulnerabilities are identified and documented.",
      informationreferences: "CIS CSC 4; COBIT 5 APO12.01, APO12.02, APO12.03, APO12.04, DSS05.01, DSS05.02; ISA 62443-2-1:2009 4.2.3, 4.2.3.7, 4.2.3.9, 4.2.3.12; ISO/IEC 27001:2013 A.12.6.1, A.18.2.3; NIST SP 800-53 Rev. 4 CA-2, CA-7, CA-8, RA-3, RA-5, SA-5, SA-11, SI-2, SI-4, SI-5"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.RA",
      categoryname: "Risk Assessment",
      categorydescription: "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
      subcategoryid: "ID.RA-2",
      subcategorydescription: "Cyber threat intelligence is received from information sharing forums and sources.",
      informationreferences: "CIS CSC 4; COBIT 5 BAI08.01; ISA 62443-2-1:2009 4.2.3, 4.2.3.9, 4.2.3.12; ISO/IEC 27001:2013 A.6.1.4; NIST SP 800-53 Rev. 4 SI-5, PM-15, PM-16"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.RA",
      categoryname: "Risk Assessment",
      categorydescription: "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
      subcategoryid: "ID.RA-3",
      subcategorydescription: "Threats, both internal and external, are identified and documented.",
      informationreferences: "CIS CSC 4; COBIT 5 APO12.01, APO12.02, APO12.03, APO12.04; ISA 62443-2-1:2009 4.2.3, 4.2.3.9, 4.2.3.12; ISO/IEC 27001:2013 Clause 6.1.2; NIST SP 800-53 Rev. 4 RA-3, SI-5, PM-12, PM-16"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.RA",
      categoryname: "Risk Assessment",
      categorydescription: "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
      subcategoryid: "ID.RA-4",
      subcategorydescription: "Potential business impacts and likelihoods are identified.",
      informationreferences: "CIS CSC 4; COBIT 5 DSS04.02; ISA 62443-2-1:2009 4.2.3, 4.2.3.9, 4.2.3.12; ISO/IEC 27001:2013 A.16.1.6, Clause 6.1.2; NIST SP 800-53 Rev. 4 RA-2, RA-3, SA-14, PM-9, PM-11"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.RA",
      categoryname: "Risk Assessment",
      categorydescription: "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
      subcategoryid: "ID.RA-5",
      subcategorydescription: "Threats, vulnerabilities, likelihoods, and impacts are used to determine risk.",
      informationreferences: "CIS CSC 4; COBIT 5 APO12.02; ISO/IEC 27001:2013 A.12.6.1; NIST SP 800-53 Rev. 4 RA-2, RA-3, PM-16"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.RA",
      categoryname: "Risk Assessment",
      categorydescription: "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
      subcategoryid: "ID.RA-6",
      subcategorydescription: "Risk responses are identified and prioritized.",
      informationreferences: "CIS CSC 4; COBIT 5 APO12.05, APO13.02; ISO/IEC 27001:2013 Clause 6.1.3; NIST SP 800-53 Rev. 4 PM-4, PM-9"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.RM",
      categoryname: "Risk Management Strategy",
      categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support operational risk decisions.",
      subcategoryid: "ID.RM-1",
      subcategorydescription: "Risk management processes are established, managed, and agreed to by organizational stakeholders.",
      informationreferences: "CIS CSC 4; COBIT 5 APO12.04, APO12.05, APO13.02, BAI02.03, BAI04.02; ISA 62443-2-1:2009 4.3.4.2; ISO/IEC 27001:2013 Clause 6.1.3, Clause 8.3, Clause 9.3; NIST SP 800-53 Rev. 4 PM-9"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.RM",
      categoryname: "Risk Management Strategy",
      categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support operational risk decisions.",
      subcategoryid: "ID.RM-2",
      subcategorydescription: "Organizational risk tolerance is determined and clearly expressed.",
      informationreferences: "COBIT 5 APO12.06; ISA 62443-2-1:2009 4.3.2.6.5; ISO/IEC 27001:2013 Clause 6.1.3, Clause 8.3; NIST SP 800-53 Rev. 4 PM-9"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.RM",
      categoryname: "Risk Management Strategy",
      categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support operational risk decisions.",
      subcategoryid: "ID.RM-3",
      subcategorydescription: "The organization’s determination of risk tolerance is informed by its role in critical infrastructure and sector specific risk analysis.",
      informationreferences: "COBIT 5 APO12.02; ISO/IEC 27001:2013 Clause 6.1.3, Clause 8.3; NIST SP 800-53 Rev. 4 SA-14, PM-8, PM-9, PM-11"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.SC",
      categoryname: "Supply Chain Risk Management",
      categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
      subcategoryid: "ID.SC-1",
      subcategorydescription: "Cyber supply chain risk management processes are identified, established, assessed, managed, and agreed to by organizational stakeholders.",
      informationreferences: "CIS CSC 4; COBIT 5 APO10.01, APO10.04, APO12.04, APO12.05, APO13.02, BAI01.03, BAI02.03, BAI04.02; ISA 62443-2-1:2009 4.3.4.2; ISO/IEC 27001:2013 A.15.1.1, A.15.1.2, A.15.1.3, A.15.2.1, A.15.2.2; NIST SP 800-53 Rev. 4 SA-9, SA-12, PM-9"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.SC",
      categoryname: "Supply Chain Risk Management",
      categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
      subcategoryid: "ID.SC-2",
      subcategorydescription: "Suppliers and third party partners of information systems, components, and services are identified, prioritized, and assessed using a cyber supply chain risk assessment process.",
      informationreferences: "COBIT 5 APO10.01, APO10.02, APO10.04, APO10.05, APO12.01, APO12.02, APO12.03, APO12.04, APO12.05, APO12.06, APO13.02, BAI02.03; ISA 62443-2-1:2009 4.2.3.1, 4.2.3.2, 4.2.3.3, 4.2.3.4, 4.2.3.6, 4.2.3.8, 4.2.3.9, 4.2.3.10, 4.2.3.12, 4.2.3.13, 4.2.3.14; ISO/IEC 27001:2013 A.15.2.1, A.15.2.2; NIST SP 800-53 Rev. 4 RA-2, RA-3, SA-12, SA-14, SA-15, PM-9"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.SC",
      categoryname: "Supply Chain Risk Management",
      categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
      subcategoryid: "ID.SC-3",
      subcategorydescription: "Contracts with suppliers and third-party partners are used to implement appropriate measures designed to meet the objectives of an organization’s cybersecurity program and Cyber Supply Chain Risk Management Plan.",
      informationreferences: "COBIT 5 APO10.01, APO10.02, APO10.03, APO10.04, APO10.05; ISA 62443-2-1:2009 4.3.2.6.4, 4.3.2.6.7; ISO/IEC 27001:2013 A.15.1.1, A.15.1.2, A.15.1.3; NIST SP 800-53 Rev. 4 SA-9, SA-11, SA-12, PM-9"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.SC",
      categoryname: "Supply Chain Risk Management",
      categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
      subcategoryid: "ID.SC-4",
      subcategorydescription: "Suppliers and third-party partners are routinely assessed using audits, test results, or other forms of evaluations to confirm they are meeting their contractual obligations.",
      informationreferences: "COBIT 5 APO10.01, APO10.03, APO10.04, APO10.05, MEA01.01, MEA01.02, MEA01.03, MEA01.04, MEA01.05 ; ISA 62443-2-1:2009 4.3.2.6.7; ISA 62443-3-3:2013 SR 6.1; ISO/IEC 27001:2013 A.15.2.1, A.15.2.2; NIST SP 800-53 Rev. 4 AU-2, AU-6, AU-12, AU-16, PS-7, SA-9, SA-12"
    },
    {
      function: "IDENTIFY (ID)",
      categoryid: "ID.SC",
      categoryname: "Supply Chain Risk Management",
      categorydescription: "The organization’s priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk. The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
      subcategoryid: "ID.SC-5",
      subcategorydescription: "Response and recovery planning and testing are conducted with suppliers and third-party providers.",
      informationreferences: "CIS CSC 19, 20; COBIT 5 DSS04.04; ISA 62443-2-1:2009 4.3.2.5.7, 4.3.4.5.11; ISA 62443-3-3:2013 SR 2.8, SR 3.3, SR.6.1, SR 7.3, SR 7.4; ISO/IEC 27001:2013 A.17.1.3; NIST SP 800-53 Rev. 4 CP-2, CP-4, IR-3, IR-4, IR-6, IR-8, IR-9"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.AC",
      categoryname: "Identity Management, Authentication and Access Control",
      categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
      subcategoryid: "PR.AC-1",
      subcategorydescription: "Identities and credentials are issued, managed, verified, revoked, and audited for authorized devices, users and processes.",
      informationreferences: "CIS CSC 1, 5, 15, 16; COBIT 5 DSS05.04, DSS06.03; ISA 62443-2-1:2009 4.3.3.5.1; ISA 62443-3-3:2013 SR 1.1, SR 1.2, SR 1.3, SR 1.4, SR 1.5, SR 1.7, SR 1.8, SR 1.9; ISO/IEC 27001:2013 A.9.2.1, A.9.2.2, A.9.2.3, A.9.2.4, A.9.2.6, A.9.3.1, A.9.4.2, A.9.4.3; NIST SP 800-53 Rev. 4 AC-1, AC-2, IA-1, IA-2, IA-3, IA-4, IA-5, IA-6, IA-7, IA-8, IA-9, IA-10, IA-11 "
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.AC",
      categoryname: "Identity Management, Authentication and Access Control",
      categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
      subcategoryid: "PR.AC-2",
      subcategorydescription: "Physical access to assets is managed and protected.",
      informationreferences: "COBIT 5 DSS01.04, DSS05.05; ISA 62443-2-1:2009 4.3.3.3.2, 4.3.3.3.8; ISO/IEC 27001:2013 A.11.1.1, A.11.1.2, A.11.1.3, A.11.1.4, A.11.1.5, A.11.1.6, A.11.2.1, A.11.2.3, A.11.2.5, A.11.2.6, A.11.2.7, A.11.2.8; NIST SP 800-53 Rev. 4 PE-2, PE-3, PE-4, PE-5, PE-6, PE-8"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.AC",
      categoryname: "Identity Management, Authentication and Access Control",
      categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
      subcategoryid: "PR.AC-3",
      subcategorydescription: "Remote access is managed.",
      informationreferences: "CIS CSC 12; COBIT 5 APO13.01, DSS01.04, DSS05.03; ISA 62443-2-1:2009 4.3.3.6.6; ISA 62443-3-3:2013 SR 1.13, SR 2.6; ISO/IEC 27001:2013 A.6.2.1, A.6.2.2, A.11.2.6, A.13.1.1, A.13.2.1; NIST SP 800-53 Rev. 4 AC-1, AC-17, AC-19, AC-20, SC-15"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.AC",
      categoryname: "Identity Management, Authentication and Access Control",
      categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
      subcategoryid: "PR.AC-4",
      subcategorydescription: "Access permissions and authorizations are managed, incorporating the principles of least privilege and separation of duties.",
      informationreferences: "CIS CSC 1; COBIT 5 BAI09.01, BAI09.02"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.AC",
      categoryname: "Identity Management, Authentication and Access Control",
      categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
      subcategoryid: "PR.AC-5",
      subcategorydescription: "Network integrity is protected (e.g., network segregation, network segmentation).",
      informationreferences: "CIS CSC 9, 14, 15, 18; COBIT 5 DSS01.05, DSS05.02; ISA 62443-2-1:2009 4.3.3.4; ISA 62443-3-3:2013 SR 3.1, SR 3.8; ISO/IEC 27001:2013 A.13.1.1, A.13.1.3, A.13.2.1, A.14.1.2, A.14.1.3; NIST SP 800-53 Rev. 4 AC-4, AC-10, SC-7"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.AC",
      categoryname: "Identity Management, Authentication and Access Control",
      categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
      subcategoryid: "PR.AC-6",
      subcategorydescription: "Identities are proofed and bound to credentials and asserted in interactions.",
      informationreferences: "CIS CSC, 16; COBIT 5 DSS05.04, DSS05.05, DSS05.07, DSS06.03 ; ISA 62443-2-1:2009 4.3.3.2.2, 4.3.3.5.2, 4.3.3.7.2, 4.3.3.7.4; ISA 62443-3-3:2013 SR 1.1, SR 1.2, SR 1.4, SR 1.5, SR 1.9, SR 2.1; ISO/IEC 27001:2013, A.7.1.1, A.9.2.1; NIST SP 800-53 Rev. 4 AC-1, AC-2, AC-3,  AC-16, AC-19, AC-24, IA-1, IA-2, IA-4, IA-5, IA-8, PE-2, PS-3"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.AC",
      categoryname: "Identity Management, Authentication and Access Control",
      categorydescription: "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
      subcategoryid: "PR.AC-7",
      subcategorydescription: "Users, devices, and other assets are authenticated (e.g., single-factor, multi-factor) commensurate with the risk of the transaction (e.g., individuals’ security and privacy risks and other organizational risks).",
      informationreferences: "CIS CSC 1, 12, 15, 16; COBIT 5 DSS05.04, DSS05.10, DSS06.10; ISA 62443-2-1:2009 4.3.3.6.1, 4.3.3.6.2, 4.3.3.6.3, 4.3.3.6.4, 4.3.3.6.5, 4.3.3.6.6, 4.3.3.6.7, 4.3.3.6.8, 4.3.3.6.9; ISA 62443-3-3:2013 SR 1.1, SR 1.2, SR 1.5, SR 1.7, SR 1.8, SR 1.9, SR 1.10; ISO/IEC 27001:2013 A.9.2.1, A.9.2.4, A.9.3.1, A.9.4.2, A.9.4.3, A.18.1.4; NIST SP 800-53 Rev. 4 AC-7, AC-8, AC-9, AC-11, AC-12, AC-14, IA-1, IA-2, IA-3, IA-4, IA-5, IA-8, IA-9, IA-10, IA-11"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.AT",
      categoryname: "Awareness and Training",
      categorydescription: "The organization’s personnel and partners are provided cybersecurity awareness education and are trained to perform their cybersecurity-related duties and responsibilities consistent with related policies, procedures, and agreements.",
      subcategoryid: "PR.AT-1",
      subcategorydescription: "All users are informed and trained.",
      informationreferences: "CIS CSC 17, 18; COBIT 5 APO07.03, BAI05.07; ISA 62443-2-1:2009 4.3.2.4.2; ISO/IEC 27001:2013 A.7.2.2, A.12.2.1; NIST SP 800-53 Rev. 4 AT-2, PM-13"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.AT",
      categoryname: "Awareness and Training",
      categorydescription: "The organization’s personnel and partners are provided cybersecurity awareness education and are trained to perform their cybersecurity-related duties and responsibilities consistent with related policies, procedures, and agreements.",
      subcategoryid: "PR.AT-2",
      subcategorydescription: "Privileged users understand their roles and responsibilities.",
      informationreferences: "CIS CSC 5, 17, 18 ; COBIT 5 APO07.02, DSS05.04, DSS06.03; ISA 62443-2-1:2009 4.3.2.4.2, 4.3.2.4.3; ISO/IEC 27001:2013 A.6.1.1, A.7.2.2; NIST SP 800-53 Rev. 4 AT-3, PM-13"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.AT",
      categoryname: "Awareness and Training",
      categorydescription: "The organization’s personnel and partners are provided cybersecurity awareness education and are trained to perform their cybersecurity-related duties and responsibilities consistent with related policies, procedures, and agreements.",
      subcategoryid: "PR.AT-3",
      subcategorydescription: "Third-party stakeholders (e.g., suppliers, customers, partners) understand their roles and responsibilities.",
      informationreferences: "CIS CSC 17; COBIT 5 APO07.03, APO07.06, APO10.04, APO10.05; ISA 62443-2-1:2009 4.3.2.4.2; ISO/IEC 27001:2013 A.6.1.1, A.7.2.1, A.7.2.2; NIST SP 800-53 Rev. 4 PS-7, SA-9, SA-16"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.AT",
      categoryname: "Awareness and Training",
      categorydescription: "The organization’s personnel and partners are provided cybersecurity awareness education and are trained to perform their cybersecurity-related duties and responsibilities consistent with related policies, procedures, and agreements.",
      subcategoryid: "PR.AT-4",
      subcategorydescription: "Senior executives understand their roles and responsibilities.",
      informationreferences: "CIS CSC 17, 19; COBIT 5 EDM01.01, APO01.02, APO07.03; ISA 62443-2-1:2009 4.3.2.4.2; ISO/IEC 27001:2013 A.6.1.1, A.7.2.2; NIST SP 800-53 Rev. 4 AT-3, PM-13"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.AT",
      categoryname: "Awareness and Training",
      categorydescription: "The organization’s personnel and partners are provided cybersecurity awareness education and are trained to perform their cybersecurity-related duties and responsibilities consistent with related policies, procedures, and agreements.",
      subcategoryid: "PR.AT-5",
      subcategorydescription: "Physical and cybersecurity personnel understand their roles and responsibilities.",
      informationreferences: "CIS CSC 17; COBIT 5 APO07.03; ISA 62443-2-1:2009 4.3.2.4.2; ISO/IEC 27001:2013 A.6.1.1, A.7.2.2; NIST SP 800-53 Rev. 4 AT-3, IR-2, PM-13"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.DS",
      categoryname: "Data Security",
      categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      subcategoryid: "PR.DS-1",
      subcategorydescription: "Data-at-rest is protected.",
      informationreferences: "CIS CSC 13, 14; COBIT 5 APO01.06, BAI02.01, BAI06.01, DSS04.07, DSS05.03, DSS06.06; ISA 62443-3-3:2013 SR 3.4, SR 4.1; ISO/IEC 27001:2013 A.8.2.3; NIST SP 800-53 Rev. 4 MP-8, SC-12, SC-28"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.DS",
      categoryname: "Data Security",
      categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      subcategoryid: "PR.DS-2",
      subcategorydescription: "Data-in-transit is protected.",
      informationreferences: "CIS CSC 13, 14; COBIT 5 APO01.06, DSS05.02, DSS06.06; ISA 62443-3-3:2013 SR 3.1, SR 3.8, SR 4.1, SR 4.2; ISO/IEC 27001:2013 A.8.2.3, A.13.1.1, A.13.2.1, A.13.2.3, A.14.1.2, A.14.1.3; NIST SP 800-53 Rev. 4 SC-8, SC-11, SC-12"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.DS",
      categoryname: "Data Security",
      categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      subcategoryid: "PR.DS-3",
      subcategorydescription: "Assets are formally managed throughout removal, transfers, and disposition.",
      informationreferences: "CIS CSC 1; COBIT 5 BAI09.03; ISA 62443-2-1:2009 4.3.3.3.9, 4.3.4.4.1; ISA 62443-3-3:2013 SR 4.2; ISO/IEC 27001:2013 A.8.2.3, A.8.3.1, A.8.3.2, A.8.3.3, A.11.2.5, A.11.2.7; NIST SP 800-53 Rev. 4 CM-8, MP-6, PE-16"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.DS",
      categoryname: "Data Security",
      categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      subcategoryid: "PR.DS-4",
      subcategorydescription: "Adequate capacity to ensure availability is maintained.",
      informationreferences: "CIS CSC 1, 2, 13; COBIT 5 APO13.01, BAI04.04; ISA 62443-3-3:2013 SR 7.1, SR 7.2; ISO/IEC 27001:2013 A.12.1.3, A.17.2.1; NIST SP 800-53 Rev. 4 AU-4, CP-2, SC-5"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.DS",
      categoryname: "Data Security",
      categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      subcategoryid: "PR.DS-5",
      subcategorydescription: "Protections against data leaks are implemented.",
      informationreferences: "CIS CSC 13; COBIT 5 APO01.06, DSS05.04, DSS05.07, DSS06.02; ISA 62443-3-3:2013 SR 5.2; ISO/IEC 27001:2013 A.6.1.2, A.7.1.1, A.7.1.2, A.7.3.1, A.8.2.2, A.8.2.3, A.9.1.1, A.9.1.2, A.9.2.3, A.9.4.1, A.9.4.4, A.9.4.5, A.10.1.1, A.11.1.4, A.11.1.5, A.11.2.1, A.13.1.1, A.13.1.3, A.13.2.1, A.13.2.3, A.13.2.4, A.14.1.2, A.14.1.3; NIST SP 800-53 Rev. 4 AC-4, AC-5, AC-6, PE-19, PS-3, PS-6, SC-7, SC-8, SC-13, SC-31, SI-4"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.DS",
      categoryname: "Data Security",
      categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      subcategoryid: "PR.DS-6",
      subcategorydescription: "Integrity checking mechanisms are used to verify software, firmware, and information integrity.",
      informationreferences: "CIS CSC 2, 3; COBIT 5 APO01.06, BAI06.01, DSS06.02; ISA 62443-3-3:2013 SR 3.1, SR 3.3, SR 3.4, SR 3.8; ISO/IEC 27001:2013 A.12.2.1, A.12.5.1, A.14.1.2, A.14.1.3, A.14.2.4; NIST SP 800-53 Rev. 4 SC-16, SI-7"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.DS",
      categoryname: "Data Security",
      categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      subcategoryid: "PR.DS-7",
      subcategorydescription: "The development and testing environment(s) are separate from the production environment.",
      informationreferences: "CIS CSC 18, 20; COBIT 5 BAI03.08, BAI07.04; ISO/IEC 27001:2013 A.12.1.4; NIST SP 800-53 Rev. 4 CM-2"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.DS",
      categoryname: "Data Security",
      categorydescription: "Information and records (data) are managed consistent with the organization’s risk strategy to protect the confidentiality, integrity, and availability of information.",
      subcategoryid: "PR.DS-8",
      subcategorydescription: "Integrity checking mechanisms are used to verify hardware integrity.",
      informationreferences: "COBIT 5 BAI03.05; ISA 62443-2-1:2009 4.3.4.4.4; ISO/IEC 27001:2013 A.11.2.4; NIST SP 800-53 Rev. 4 SA-10, SI-7"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.IP",
      categoryname: "Information Protection Processes and Procedures",
      categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      subcategoryid: "PR.IP-1",
      subcategorydescription: "A baseline configuration of information technology/industrial control systems is created and maintained incorporating security principles (e.g. concept of least functionality).",
      informationreferences: "CIS CSC 3, 9, 11; COBIT 5 BAI10.01, BAI10.02, BAI10.03, BAI10.05; ISA 62443-2-1:2009 4.3.4.3.2, 4.3.4.3.3; ISA 62443-3-3:2013 SR 7.6; ISO/IEC 27001:2013 A.12.1.2, A.12.5.1, A.12.6.2, A.14.2.2, A.14.2.3, A.14.2.4; NIST SP 800-53 Rev. 4 CM-2, CM-3, CM-4, CM-5, CM-6, CM-7, CM-9, SA-10"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.IP",
      categoryname: "Information Protection Processes and Procedures",
      categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      subcategoryid: "PR.IP-2",
      subcategorydescription: "A System Development Life Cycle to manage systems is implemented.",
      informationreferences: "CIS CSC 18; COBIT 5 APO13.01, BAI03.01, BAI03.02, BAI03.03; ISA 62443-2-1:2009 4.3.4.3.3; ISO/IEC 27001:2013 A.6.1.5, A.14.1.1, A.14.2.1, A.14.2.5; NIST SP 800-53 Rev. 4 PL-8, SA-3, SA-4, SA-8, SA-10, SA-11, SA-12, SA-15, SA-17, SI-12, SI-13, SI-14, SI-16, SI-17 "
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.IP",
      categoryname: "Information Protection Processes and Procedures",
      categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      subcategoryid: "PR.IP-3",
      subcategorydescription: "Configuration change control processes are in place.",
      informationreferences: "CIS CSC 3, 11; COBIT 5 BAI01.06, BAI06.01; ISA 62443-2-1:2009 4.3.4.3.2, 4.3.4.3.3; ISA 62443-3-3:2013 SR 7.6; ISO/IEC 27001:2013 A.12.1.2, A.12.5.1, A.12.6.2, A.14.2.2, A.14.2.3, A.14.2.4; NIST SP 800-53 Rev. 4 CM-3, CM-4, SA-10"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.IP",
      categoryname: "Information Protection Processes and Procedures",
      categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      subcategoryid: "PR.IP-4",
      subcategorydescription: "Backups of information are conducted, maintained, and tested.",
      informationreferences: "CIS CSC 10; COBIT 5 APO13.01, DSS01.01, DSS04.07 ; ISA 62443-2-1:2009 4.3.4.3.9; ISA 62443-3-3:2013 SR 7.3, SR 7.4; ISO/IEC 27001:2013 A.12.3.1, A.17.1.2, A.17.1.3, A.18.1.3; NIST SP 800-53 Rev. 4 CP-4, CP-6, CP-9"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.IP",
      categoryname: "Information Protection Processes and Procedures",
      categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      subcategoryid: "PR.IP-5",
      subcategorydescription: "Policy and regulations regarding the physical operating environment for organizational assets are met.",
      informationreferences: "COBIT 5 DSS01.04, DSS05.05; ISA 62443-2-1:2009 4.3.3.3.1 4.3.3.3.2, 4.3.3.3.3, 4.3.3.3.5, 4.3.3.3.6; ISO/IEC 27001:2013 A.11.1.4, A.11.2.1, A.11.2.2, A.11.2.3; NIST SP 800-53 Rev. 4 PE-10, PE-12, PE-13, PE-14, PE-15, PE-18"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.IP",
      categoryname: "Information Protection Processes and Procedures",
      categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      subcategoryid: "PR.IP-6",
      subcategorydescription: "Data is destroyed according to policy.",
      informationreferences: "COBIT 5 BAI09.03, DSS05.06; ISA 62443-2-1:2009 4.3.4.4.4; ISA 62443-3-3:2013 SR 4.2; ISO/IEC 27001:2013 A.8.2.3, A.8.3.1, A.8.3.2, A.11.2.7; NIST SP 800-53 Rev. 4 MP-6"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.IP",
      categoryname: "Information Protection Processes and Procedures",
      categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      subcategoryid: "PR.IP-7",
      subcategorydescription: "Protection processes are improved.",
      informationreferences: "COBIT 5 APO11.06, APO12.06, DSS04.05; ISA 62443-2-1:2009 4.4.3.1, 4.4.3.2, 4.4.3.3, 4.4.3.4, 4.4.3.5, 4.4.3.6, 4.4.3.7, 4.4.3.8; ISO/IEC 27001:2013 A.16.1.6, Clause 9, Clause 10; NIST SP 800-53 Rev. 4 CA-2, CA-7, CP-2, IR-8, PL-2, PM-6"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.IP",
      categoryname: "Information Protection Processes and Procedures",
      categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      subcategoryid: "PR.IP-8",
      subcategorydescription: "Effectiveness of protection technologies is shared.",
      informationreferences: "COBIT 5 BAI08.04, DSS03.04; ISO/IEC 27001:2013 A.16.1.6; NIST SP 800-53 Rev. 4 AC-21, CA-7, SI-4"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.IP",
      categoryname: "Information Protection Processes and Procedures",
      categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      subcategoryid: "PR.IP-9",
      subcategorydescription: "Response plans (Incident Response and Business Continuity) and recovery plans (Incident Recovery and Disaster Recovery) are in place and managed.",
      informationreferences: "CIS CSC 19; COBIT 5 APO12.06, DSS04.03; ISA 62443-2-1:2009 4.3.2.5.3, 4.3.4.5.1; ISO/IEC 27001:2013 A.16.1.1, A.17.1.1, A.17.1.2, A.17.1.3; NIST SP 800-53 Rev. 4 CP-2, CP-7, CP-12, CP-13, IR-7, IR-8, IR-9, PE-17"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.IP",
      categoryname: "Information Protection Processes and Procedures",
      categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      subcategoryid: "PR.IP-10",
      subcategorydescription: "Response and recovery plans are tested.",
      informationreferences: "CIS CSC 19, 20; COBIT 5 DSS04.04; ISA 62443-2-1:2009 4.3.2.5.7, 4.3.4.5.11; ISA 62443-3-3:2013 SR 3.3; ISO/IEC 27001:2013 A.17.1.3; NIST SP 800-53 Rev. 4 CP-4, IR-3, PM-14"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.IP",
      categoryname: "Information Protection Processes and Procedures",
      categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      subcategoryid: "PR.IP-11",
      subcategorydescription: "Cybersecurity is included in human resources practices (e.g., deprovisioning, personnel screening).",
      informationreferences: "CIS CSC 5, 16; COBIT 5 APO07.01, APO07.02, APO07.03, APO07.04, APO07.05; ISA 62443-2-1:2009 4.3.3.2.1, 4.3.3.2.2, 4.3.3.2.3; ISO/IEC 27001:2013 A.7.1.1, A.7.1.2, A.7.2.1, A.7.2.2, A.7.2.3, A.7.3.1, A.8.1.4; NIST SP 800-53 Rev. 4 PS-1, PS-2, PS-3, PS-4, PS-5, PS-6, PS-7, PS-8, SA-21"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.IP",
      categoryname: "Information Protection Processes and Procedures",
      categorydescription: "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
      subcategoryid: "PR.IP-12",
      subcategorydescription: "A vulnerability management plan is developed and implemented.",
      informationreferences: "CIS CSC 4, 18, 20; COBIT 5 BAI03.10, DSS05.01, DSS05.02; ISO/IEC 27001:2013 A.12.6.1, A.14.2.3, A.16.1.3, A.18.2.2, A.18.2.3; NIST SP 800-53 Rev. 4 RA-3, RA-5, SI-2"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.MA",
      categoryname: "Maintenance",
      categorydescription: "Maintenance and repairs of industrial control and information system components are performed consistent with policies and procedures.",
      subcategoryid: "PR.MA-1",
      subcategorydescription: "Maintenance and repair of organizational assets are performed and logged, with approved and controlled tools.",
      informationreferences: "COBIT 5 BAI03.10, BAI09.02, BAI09.03, DSS01.05; ISA 62443-2-1:2009 4.3.3.3.7; ISO/IEC 27001:2013 A.11.1.2, A.11.2.4, A.11.2.5, A.11.2.6; NIST SP 800-53 Rev. 4 MA-2, MA-3, MA-5, MA-6"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.MA",
      categoryname: "Maintenance",
      categorydescription: "Maintenance and repairs of industrial control and information system components are performed consistent with policies and procedures.",
      subcategoryid: "PR.MA-2",
      subcategorydescription: "Remote maintenance of organizational assets is approved, logged, and performed in a manner that prevents unauthorized access.",
      informationreferences: "CIS CSC 3, 5; COBIT 5 DSS05.04; ISA 62443-2-1:2009 4.3.3.6.5, 4.3.3.6.6, 4.3.3.6.7, 4.3.3.6.8; ISO/IEC 27001:2013 A.11.2.4, A.15.1.1, A.15.2.1; NIST SP 800-53 Rev. 4 MA-4"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.PT",
      categoryname: "Protective Technology",
      categorydescription: "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
      subcategoryid: "PR.PT-1",
      subcategorydescription: "Audit/log records are determined, documented, implemented, and reviewed in accordance with policy.",
      informationreferences: "CIS CSC 1, 3, 5, 6, 14, 15, 16; COBIT 5 APO11.04, BAI03.05, DSS05.04, DSS05.07, MEA02.01; ISA 62443-2-1:2009 4.3.3.3.9, 4.3.3.5.8, 4.3.4.4.7, 4.4.2.1, 4.4.2.2, 4.4.2.4; ISA 62443-3-3:2013 SR 2.8, SR 2.9, SR 2.10, SR 2.11, SR 2.12; ISO/IEC 27001:2013 A.12.4.1, A.12.4.2, A.12.4.3, A.12.4.4, A.12.7.1; NIST SP 800-53 Rev. 4 AU Family"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.PT",
      categoryname: "Protective Technology",
      categorydescription: "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
      subcategoryid: "PR.PT-2",
      subcategorydescription: "Removable media is protected and its use restricted according to policy.",
      informationreferences: "CIS CSC 8, 13; COBIT 5 APO13.01, DSS05.02, DSS05.06; ISA 62443-3-3:2013 SR 2.3; ISO/IEC 27001:2013 A.8.2.1, A.8.2.2, A.8.2.3, A.8.3.1, A.8.3.3, A.11.2.9; NIST SP 800-53 Rev. 4 MP-2, MP-3, MP-4, MP-5, MP-7, MP-8"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.PT",
      categoryname: "Protective Technology",
      categorydescription: "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
      subcategoryid: "PR.PT-3",
      subcategorydescription: "The principle of least functionality is incorporated by configuring systems to provide only essential capabilities.",
      informationreferences: "CIS CSC 3, 11, 14; COBIT 5 DSS05.02, DSS05.05, DSS06.06; ISA 62443-2-1:2009 4.3.3.5.1, 4.3.3.5.2, 4.3.3.5.3, 4.3.3.5.4, 4.3.3.5.5, 4.3.3.5.6, 4.3.3.5.7, 4.3.3.5.8, 4.3.3.6.1, 4.3.3.6.2, 4.3.3.6.3, 4.3.3.6.4, 4.3.3.6.5, 4.3.3.6.6, 4.3.3.6.7, 4.3.3.6.8, 4.3.3.6.9, 4.3.3.7.1, 4.3.3.7.2, 4.3.3.7.3, 4.3.3.7.4; ISA 62443-3-3:2013 SR 1.1, SR 1.2, SR 1.3, SR 1.4, SR 1.5, SR 1.6, SR 1.7, SR 1.8, SR 1.9, SR 1.10, SR 1.11, SR 1.12, SR 1.13, SR 2.1, SR 2.2, SR 2.3, SR 2.4, SR 2.5, SR 2.6, SR 2.7; ISO/IEC 27001:2013 A.9.1.2; NIST SP 800-53 Rev. 4 AC-3, CM-7"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.PT",
      categoryname: "Protective Technology",
      categorydescription: "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
      subcategoryid: "PR.PT-4",
      subcategorydescription: "Communications and control networks are protected.",
      informationreferences: "CIS CSC 8, 12, 15; COBIT 5 DSS05.02, APO13.01; ISA 62443-3-3:2013 SR 3.1, SR 3.5, SR 3.8, SR 4.1, SR 4.3, SR 5.1, SR 5.2, SR 5.3, SR 7.1, SR 7.6; ISO/IEC 27001:2013 A.13.1.1, A.13.2.1, A.14.1.3; NIST SP 800-53 Rev. 4 AC-4, AC-17, AC-18, CP-8, SC-7, SC-19, SC-20, SC-21, SC-22, SC-23, SC-24, SC-25, SC-29, SC-32, SC-36, SC-37, SC-38, SC-39, SC-40, SC-41, SC-43"
    },
    {
      function: "PROTECT (PR)",
      categoryid: "PR.PT",
      categoryname: "Protective Technology",
      categorydescription: "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
      subcategoryid: "PR.PT-5",
      subcategorydescription: "Mechanisms (e.g., failsafe, load balancing, hot swap) are implemented to achieve resilience requirements in normal and adverse situations.",
      informationreferences: "COBIT 5 BAI04.01, BAI04.02, BAI04.03, BAI04.04, BAI04.05, DSS01.05; ISA 62443-2-1:2009 4.3.2.5.2; ISA 62443-3-3:2013 SR 7.1, SR 7.2; ISO/IEC 27001:2013 A.17.1.2, A.17.2.1 ; NIST SP 800-53 Rev. 4 CP-7, CP-8, CP-11, CP-13, PL-8, SA-14, SC-6"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.AE",
      categoryname: "Anomalies and Events",
      categorydescription: "Anomalous activity is detected and the potential impact of events is understood.",
      subcategoryid: "DE.AE-1",
      subcategorydescription: "A baseline of network operations and expected data flows for users and systems is established and managed.",
      informationreferences: "CIS CSC 1, 4, 6, 12, 13, 15, 16; COBIT 5 DSS03.01; ISA 62443-2-1:2009 4.4.3.3; ISO/IEC 27001:2013 A.12.1.1, A.12.1.2, A.13.1.1, A.13.1.2; NIST SP 800-53 Rev. 4 AC-4, CA-3, CM-2, SI-4"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.AE",
      categoryname: "Anomalies and Events",
      categorydescription: "Anomalous activity is detected and the potential impact of events is understood.",
      subcategoryid: "DE.AE-2",
      subcategorydescription: "Detected events are analyzed to understand attack targets and methods.",
      informationreferences: "CIS CSC 3, 6, 13, 15; COBIT 5 DSS05.07; ISA 62443-2-1:2009 4.3.4.5.6, 4.3.4.5.7, 4.3.4.5.8; ISA 62443-3-3:2013 SR 2.8, SR 2.9, SR 2.10, SR 2.11, SR 2.12, SR 3.9, SR 6.1, SR 6.2; ISO/IEC 27001:2013 A.12.4.1, A.16.1.1, A.16.1.4; NIST SP 800-53 Rev. 4 AU-6, CA-7, IR-4, SI-4"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.AE",
      categoryname: "Anomalies and Events",
      categorydescription: "Anomalous activity is detected and the potential impact of events is understood.",
      subcategoryid: "DE.AE-3",
      subcategorydescription: "Event data are collected and correlated from multiple sources and sensors.",
      informationreferences: "CIS CSC 1, 3, 4, 5, 6, 7, 8, 11, 12, 13, 14, 15, 16; COBIT 5 BAI08.02; ISA 62443-3-3:2013 SR 6.1; ISO/IEC 27001:2013 A.12.4.1, A.16.1.7; NIST SP 800-53 Rev. 4 AU-6, CA-7, IR-4, IR-5, IR-8, SI-4"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.AE",
      categoryname: "Anomalies and Events",
      categorydescription: "Anomalous activity is detected and the potential impact of events is understood.",
      subcategoryid: "DE.AE-4",
      subcategorydescription: "Impact of events is determined.",
      informationreferences: "CIS CSC 4, 6; COBIT 5 APO12.06, DSS03.01; ISO/IEC 27001:2013 A.16.1.4; NIST SP 800-53 Rev. 4 CP-2, IR-4, RA-3, SI-4"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.AE",
      categoryname: "Anomalies and Events",
      categorydescription: "Anomalous activity is detected and the potential impact of events is understood.",
      subcategoryid: "DE.AE-5",
      subcategorydescription: "Incident alert thresholds are established.",
      informationreferences: "CIS CSC 6, 19; COBIT 5 APO12.06, DSS03.01; ISA 62443-2-1:2009 4.2.3.10; ISO/IEC 27001:2013 A.16.1.4; NIST SP 800-53 Rev. 4 IR-4, IR-5, IR-8"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.CM",
      categoryname: "Security Continuous Monitoring",
      categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
      subcategoryid: "DE.CM-1",
      subcategorydescription: "The network is monitored to detect potential cybersecurity events.",
      informationreferences: "CIS CSC 1, 7, 8, 12, 13, 15, 16; COBIT 5 DSS01.03, DSS03.05, DSS05.07; ISA 62443-3-3:2013 SR 6.2; NIST SP 800-53 Rev. 4 AC-2, AU-12, CA-7, CM-3, SC-5, SC-7, SI-4"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.CM",
      categoryname: "Security Continuous Monitoring",
      categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
      subcategoryid: "DE.CM-2",
      subcategorydescription: "The physical environment is monitored to detect potential cybersecurity events.",
      informationreferences: "COBIT 5 DSS01.04, DSS01.05; ISA 62443-2-1:2009 4.3.3.3.8; ISO/IEC 27001:2013 A.11.1.1, A.11.1.2; NIST SP 800-53 Rev. 4 CA-7, PE-3, PE-6, PE-20"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.CM",
      categoryname: "Security Continuous Monitoring",
      categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
      subcategoryid: "DE.CM-3",
      subcategorydescription: "Personnel activity is monitored to detect potential cybersecurity events.",
      informationreferences: "CIS CSC 5, 7, 14, 16; COBIT 5 DSS05.07; ISA 62443-3-3:2013 SR 6.2; ISO/IEC 27001:2013 A.12.4.1, A.12.4.3; NIST SP 800-53 Rev. 4 AC-2, AU-12, AU-13, CA-7, CM-10, CM-11"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.CM",
      categoryname: "Security Continuous Monitoring",
      categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
      subcategoryid: "DE.CM-4",
      subcategorydescription: "Malicious code is detected.",
      informationreferences: "CIS CSC 4, 7, 8, 12; COBIT 5 DSS05.01; ISA 62443-2-1:2009 4.3.4.3.8; ISA 62443-3-3:2013 SR 3.2; ISO/IEC 27001:2013 A.12.2.1; NIST SP 800-53 Rev. 4 SI-3, SI-8"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.CM",
      categoryname: "Security Continuous Monitoring",
      categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
      subcategoryid: "DE.CM-5",
      subcategorydescription: "Unauthorized mobile code is detected.",
      informationreferences: "CIS CSC 7, 8; COBIT 5 DSS05.01; ISA 62443-3-3:2013 SR 2.4; ISO/IEC 27001:2013 A.12.5.1, A.12.6.2; NIST SP 800-53 Rev. 4 SC-18, SI-4, SC-44"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.CM",
      categoryname: "Security Continuous Monitoring",
      categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
      subcategoryid: "DE.CM-6",
      subcategorydescription: "External service provider activity is monitored to detect potential cybersecurity events.",
      informationreferences: "COBIT 5 APO07.06, APO10.05; ISO/IEC 27001:2013 A.14.2.7, A.15.2.1; NIST SP 800-53 Rev. 4 CA-7, PS-7, SA-4, SA-9, SI-4"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.CM",
      categoryname: "Security Continuous Monitoring",
      categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
      subcategoryid: "DE.CM-7",
      subcategorydescription: "Monitoring for unauthorized personnel, connections, devices, and software is performed.",
      informationreferences: "CIS CSC 1, 2, 3, 5, 9, 12, 13, 15, 16; COBIT 5 DSS05.02, DSS05.05; ISO/IEC 27001:2013 A.12.4.1, A.14.2.7, A.15.2.1; NIST SP 800-53 Rev. 4 AU-12, CA-7, CM-3, CM-8, PE-3, PE-6, PE-20, SI-4"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.CM",
      categoryname: "Security Continuous Monitoring",
      categorydescription: "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
      subcategoryid: "DE.CM-8",
      subcategorydescription: "Vulnerability scans are performed.",
      informationreferences: "CIS CSC 4, 20; COBIT 5 BAI03.10, DSS05.01; ISA 62443-2-1:2009 4.2.3.1, 4.2.3.7; ISO/IEC 27001:2013 A.12.6.1; NIST SP 800-53 Rev. 4 RA-5"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.DP",
      categoryname: "Detection Processes",
      categorydescription: "Detection processes and procedures are maintained and tested to ensure awareness of anomalous events.",
      subcategoryid: "DE.DP-1",
      subcategorydescription: "Roles and responsibilities for detection are well defined to ensure accountability.",
      informationreferences: "CIS CSC 19; COBIT 5 APO01.02, DSS05.01, DSS06.03; ISA 62443-2-1:2009 4.4.3.1; ISO/IEC 27001:2013 A.6.1.1, A.7.2.2; NIST SP 800-53 Rev. 4 CA-2, CA-7, PM-14"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.DP",
      categoryname: "Detection Processes",
      categorydescription: "Detection processes and procedures are maintained and tested to ensure awareness of anomalous events.",
      subcategoryid: "DE.DP-2",
      subcategorydescription: "Detection activities comply with all applicable requirements.",
      informationreferences: "COBIT 5 DSS06.01, MEA03.03, MEA03.04; ISA 62443-2-1:2009 4.4.3.2; ISO/IEC 27001:2013 A.18.1.4, A.18.2.2, A.18.2.3; NIST SP 800-53 Rev. 4 AC-25, CA-2, CA-7, SA-18, SI-4, PM-14"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.DP",
      categoryname: "Detection Processes",
      categorydescription: "Detection processes and procedures are maintained and tested to ensure awareness of anomalous events.",
      subcategoryid: "DE.DP-3",
      subcategorydescription: "Detection processes are tested.",
      informationreferences: "COBIT 5 APO13.02, DSS05.02; ISA 62443-2-1:2009 4.4.3.2; ISA 62443-3-3:2013 SR 3.3; ISO/IEC 27001:2013 A.14.2.8; NIST SP 800-53 Rev. 4 CA-2, CA-7, PE-3, SI-3, SI-4, PM-14"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.DP",
      categoryname: "Detection Processes",
      categorydescription: "Detection processes and procedures are maintained and tested to ensure awareness of anomalous events.",
      subcategoryid: "DE.DP-4",
      subcategorydescription: "Event detection information is communicated.",
      informationreferences: "CIS CSC 19; COBIT 5 APO08.04, APO12.06, DSS02.05; ISA 62443-2-1:2009 4.3.4.5.9; ISA 62443-3-3:2013 SR 6.1; ISO/IEC 27001:2013 A.16.1.2, A.16.1.3; NIST SP 800-53 Rev. 4 AU-6, CA-2, CA-7,  RA-5, SI-4"
    },
    {
      function: "DETECT (DE)",
      categoryid: "DE.DP",
      categoryname: "Detection Processes",
      categorydescription: "Detection processes and procedures are maintained and tested to ensure awareness of anomalous events.",
      subcategoryid: "DE.DP-5",
      subcategorydescription: "Detection processes are continuously improved.",
      informationreferences: "COBIT 5 APO11.06, APO12.06, DSS04.05; ISA 62443-2-1:2009 4.4.3.4; ISO/IEC 27001:2013 A.16.1.6; NIST SP 800-53 Rev. 4, CA-2, CA-7, PL-2, RA-5, SI-4, PM-14"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.RP",
      categoryname: "Response Planning",
      categorydescription: "Response processes and procedures are executed and maintained, to ensure response to detected cybersecurity incidents.",
      subcategoryid: "RS.RP-1",
      subcategorydescription: "Response plan is executed during or after an incident.",
      informationreferences: "CIS CSC 19; COBIT 5 APO12.06, BAI01.10; ISA 62443-2-1:2009 4.3.4.5.1; ISO/IEC 27001:2013 A.16.1.5; NIST SP 800-53 Rev. 4 CP-2, CP-10, IR-4, IR-8"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.CO",
      categoryname: "Communications",
      categorydescription: "Response activities are coordinated with internal and external stakeholders (e.g. external support from law enforcement agencies).",
      subcategoryid: "RS.CO-1",
      subcategorydescription: "Personnel know their roles and order of operations when a response is needed.",
      informationreferences: "CIS CSC 19; COBIT 5 EDM03.02, APO01.02, APO12.03; ISA 62443-2-1:2009 4.3.4.5.2, 4.3.4.5.3, 4.3.4.5.4; ISO/IEC 27001:2013 A.6.1.1, A.7.2.2, A.16.1.1; NIST SP 800-53 Rev. 4 CP-2, CP-3, IR-3, IR-8"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.CO",
      categoryname: "Communications",
      categorydescription: "Response activities are coordinated with internal and external stakeholders (e.g. external support from law enforcement agencies).",
      subcategoryid: "RS.CO-2",
      subcategorydescription: "Incidents are reported consistent with established criteria.",
      informationreferences: "CIS CSC 19; COBIT 5 DSS01.03; ISA 62443-2-1:2009 4.3.4.5.5; ISO/IEC 27001:2013 A.6.1.3, A.16.1.2; NIST SP 800-53 Rev. 4 AU-6, IR-6, IR-8"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.CO",
      categoryname: "Communications",
      categorydescription: "Response activities are coordinated with internal and external stakeholders (e.g. external support from law enforcement agencies).",
      subcategoryid: "RS.CO-3",
      subcategorydescription: "Information is shared consistent with response plans.",
      informationreferences: "CIS CSC 19; COBIT 5 DSS03.04; ISA 62443-2-1:2009 4.3.4.5.2; ISO/IEC 27001:2013 A.16.1.2, Clause 7.4, Clause 16.1.2; NIST SP 800-53 Rev. 4 CA-2, CA-7, CP-2, IR-4, IR-8, PE-6, RA-5, SI-4"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.CO",
      categoryname: "Communications",
      categorydescription: "Response activities are coordinated with internal and external stakeholders (e.g. external support from law enforcement agencies).",
      subcategoryid: "RS.CO-4",
      subcategorydescription: "Coordination with stakeholders occurs consistent with response plans.",
      informationreferences: "CIS CSC 19; COBIT 5 DSS03.04; ISA 62443-2-1:2009 4.3.4.5.5; ISO/IEC 27001:2013 Clause 7.4; NIST SP 800-53 Rev. 4 CP-2, IR-4, IR-8"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.CO",
      categoryname: "Communications",
      categorydescription: "Response activities are coordinated with internal and external stakeholders (e.g. external support from law enforcement agencies).",
      subcategoryid: "RS.CO-5",
      subcategorydescription: "Voluntary information sharing occurs with external stakeholders to achieve broader cybersecurity situational awareness.",
      informationreferences: "CIS CSC 19; COBIT 5 BAI08.04; ISO/IEC 27001:2013 A.6.1.4; NIST SP 800-53 Rev. 4 SI-5, PM-15"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.AN",
      categoryname: "Analysis",
      categorydescription: "Analysis is conducted to ensure effective response and support recovery activities.",
      subcategoryid: "RS.AN-1",
      subcategorydescription: "Notifications from detection systems are investigated.",
      informationreferences: "CIS CSC 4, 6, 8, 19; COBIT 5 DSS02.04, DSS02.07; ISA 62443-2-1:2009 4.3.4.5.6, 4.3.4.5.7, 4.3.4.5.8; ISA 62443-3-3:2013 SR 6.1; ISO/IEC 27001:2013 A.12.4.1, A.12.4.3, A.16.1.5; NIST SP 800-53 Rev. 4 AU-6, CA-7, IR-4, IR-5, PE-6, SI-4"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.AN",
      categoryname: "Analysis",
      categorydescription: "Analysis is conducted to ensure effective response and support recovery activities.",
      subcategoryid: "RS.AN-2",
      subcategorydescription: "The impact of the incident is understood.",
      informationreferences: "COBIT 5 DSS02.02; ISA 62443-2-1:2009 4.3.4.5.6, 4.3.4.5.7, 4.3.4.5.8; ISO/IEC 27001:2013 A.16.1.4, A.16.1.6; NIST SP 800-53 Rev. 4 CP-2, IR-4"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.AN",
      categoryname: "Analysis",
      categorydescription: "Analysis is conducted to ensure effective response and support recovery activities.",
      subcategoryid: "RS.AN-3",
      subcategorydescription: "Forensics are performed.",
      informationreferences: "COBIT 5 APO12.06, DSS03.02, DSS05.07; ISA 62443-3-3:2013 SR 2.8, SR 2.9, SR 2.10, SR 2.11, SR 2.12, SR 3.9, SR 6.1; ISO/IEC 27001:2013 A.16.1.7; NIST SP 800-53 Rev. 4 AU-7, IR-4"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.AN",
      categoryname: "Analysis",
      categorydescription: "Analysis is conducted to ensure effective response and support recovery activities.",
      subcategoryid: "RS.AN-4",
      subcategorydescription: "Incidents are categorized consistent with response plans.",
      informationreferences: "CIS CSC 19; COBIT 5 DSS02.02; ISA 62443-2-1:2009 4.3.4.5.6; ISO/IEC 27001:2013 A.16.1.4; NIST SP 800-53 Rev. 4 CP-2, IR-4, IR-5, IR-8"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.AN",
      categoryname: "Analysis",
      categorydescription: "Analysis is conducted to ensure effective response and support recovery activities.",
      subcategoryid: "RS.AN-5",
      subcategorydescription: "Processes are established to receive, analyze and respond to vulnerabilities disclosed to the organization from internal and external sources.",
      informationreferences: "CIS CSC 4, 19; COBIT 5 EDM03.02, DSS05.07; NIST SP 800-53 Rev. 4 SI-5, PM-15"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.MI",
      categoryname: "Mitigation",
      categorydescription: "Activities are performed to prevent expansion of an event, mitigate its effects, and resolve the incident.",
      subcategoryid: "RS.MI-1",
      subcategorydescription: "Incidents are contained.",
      informationreferences: "CIS CSC 19; COBIT 5 APO12.06; ISA 62443-2-1:2009 4.3.4.5.6; ISA 62443-3-3:2013 SR 5.1, SR 5.2, SR 5.4; ISO/IEC 27001:2013 A.12.2.1, A.16.1.5; NIST SP 800-53 Rev. 4 IR-4"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.MI",
      categoryname: "Mitigation",
      categorydescription: "Activities are performed to prevent expansion of an event, mitigate its effects, and resolve the incident.",
      subcategoryid: "RS.MI-2",
      subcategorydescription: "Incidents are mitigated.",
      informationreferences: "CIS CSC 4, 19; COBIT 5 APO12.06; ISA 62443-2-1:2009 4.3.4.5.6, 4.3.4.5.10; ISO/IEC 27001:2013 A.12.2.1, A.16.1.5; NIST SP 800-53 Rev. 4 IR-4"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.MI",
      categoryname: "Mitigation",
      categorydescription: "Activities are performed to prevent expansion of an event, mitigate its effects, and resolve the incident.",
      subcategoryid: "RS.MI-3",
      subcategorydescription: "Newly identified vulnerabilities are mitigated or documented as accepted risks.",
      informationreferences: "CIS CSC 4; COBIT 5 APO12.06; ISO/IEC 27001:2013 A.12.6.1; NIST SP 800-53 Rev. 4 CA-7, RA-3, RA-5"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.IM",
      categoryname: "Improvements",
      categorydescription: "Organizational response activities are improved by incorporating lessons learned from current and previous detection/response activities.",
      subcategoryid: "RS.IM-1",
      subcategorydescription: "Response plans incorporate lessons learned.",
      informationreferences: "COBIT 5 BAI01.13; ISA 62443-2-1:2009 4.3.4.5.10, 4.4.3.4; ISO/IEC 27001:2013 A.16.1.6, Clause 10; NIST SP 800-53 Rev. 4 CP-2, IR-4, IR-8"
    },
    {
      function: "RESPOND (RS)",
      categoryid: "RS.IM",
      categoryname: "Improvements",
      categorydescription: "Organizational response activities are improved by incorporating lessons learned from current and previous detection/response activities.",
      subcategoryid: "RS.IM-2",
      subcategorydescription: "Response strategies are updated.",
      informationreferences: "COBIT 5 BAI01.13, DSS04.08; ISO/IEC 27001:2013 A.16.1.6, Clause 10; NIST SP 800-53 Rev. 4 CP-2, IR-4, IR-8"
    },
    {
      function: "RECOVER (RC)",
      categoryid: "RC.RP",
      categoryname: "Recovery Planning",
      categorydescription: "Recovery processes and procedures are executed and maintained to ensure restoration of systems or assets affected by cybersecurity incidents.",
      subcategoryid: "RC.RP-1",
      subcategorydescription: "Recovery plan is executed during or after a cybersecurity incident.",
      informationreferences: "CIS CSC 10; COBIT 5 APO12.06, DSS02.05, DSS03.04; ISO/IEC 27001:2013 A.16.1.5; NIST SP 800-53 Rev. 4 CP-10, IR-4, IR-8"
    },
    {
      function: "RECOVER (RC)",
      categoryid: "RC.IM",
      categoryname: "Improvements",
      categorydescription: "Recovery planning and processes are improved by incorporating lessons learned into future activities.",
      subcategoryid: "RC.IM-1",
      subcategorydescription: "Recovery plans incorporate lessons learned.",
      informationreferences: "COBIT 5 APO12.06, BAI05.07, DSS04.08; ISA 62443-2-1:2009 4.4.3.4; ISO/IEC 27001:2013 A.16.1.6, Clause 10; NIST SP 800-53 Rev. 4 CP-2, IR-4, IR-8"
    },
    {
      function: "RECOVER (RC)",
      categoryid: "RC.IM",
      categoryname: "Improvements",
      categorydescription: "Recovery planning and processes are improved by incorporating lessons learned into future activities.",
      subcategoryid: "RC.IM-2",
      subcategorydescription: "Recovery strategies are updated.",
      informationreferences: "COBIT 5 APO12.06, BAI07.08; ISO/IEC 27001:2013 A.16.1.6, Clause 10; NIST SP 800-53 Rev. 4 CP-2, IR-4, IR-8"
    },
    {
      function: "RECOVER (RC)",
      categoryid: "RC.CO",
      categoryname: "Communications",
      categorydescription: "Restoration activities are coordinated with internal and external parties (e.g. coordinating centers, Internet Service Providers, owners of attacking systems, victims, other CSIRTs, and vendors.",
      subcategoryid: "RC.CO-1",
      subcategorydescription: "Public relations are managed.",
      informationreferences: "COBIT 5 EDM03.02; ISO/IEC 27001:2013 A.6.1.4, Clause 7.4"
    },
    {
      function: "RECOVER (RC)",
      categoryid: "RC.CO",
      categoryname: "Communications",
      categorydescription: "Restoration activities are coordinated with internal and external parties (e.g. coordinating centers, Internet Service Providers, owners of attacking systems, victims, other CSIRTs, and vendors.",
      subcategoryid: "RC.CO-2",
      subcategorydescription: "Reputation is repaired after an incident.",
      informationreferences: "COBIT 5 MEA03.02; ISO/IEC 27001:2013 Clause 7.4"
    },
    {
      function: "RECOVER (RC)",
      categoryid: "RC.CO",
      categoryname: "Communications",
      categorydescription: "Restoration activities are coordinated with internal and external parties (e.g. coordinating centers, Internet Service Providers, owners of attacking systems, victims, other CSIRTs, and vendors.",
      subcategoryid: "RC.CO-3",
      subcategorydescription: "Recovery activities are communicated to internal and external stakeholders as well as executive and management teams.",
      informationreferences: "COBIT 5 APO12.06; ISO/IEC 27001:2013 Clause 7.4; NIST SP 800-53 Rev. 4 CP-2, IR-4 "
    }
  ], []);




  return (
    <div
      className="App"
    >
      <div
        className="Text-AF"
      >
        <p
          style={{ color: "grey" }}
        >
          Managed and developed <br />by &nbsp;
          <a
            style={{ color: "lightblue" }}
            href="https://twitter.com/arcticfrenz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="twitter"
              alt="twitter"
              src="/twitter.png"
            />
            @ArcticFrenz
          </a>
        </p>
      </div>
      <header
        className="App-header"
      >

        <div
          className="App-relative"
        >
          <span
            className="App-item"
          >
            <img
              src={gif}
              className="App-logo"
              alt="logo"
            />
          </span>
          {
            <div
              id="mychart"
            />
          }
        </div>
        <div
          className="Text-header"
        >
          <h1
            className="fire"
          >
            SMART PHOENIX
          </h1>
          <p>
            Struggling to keep up with the ever-changing cybersecurity landscape? SMART PHOENIX is a NIST Cybersecurity Framework Core sunburst chart that can help you visualize and understand cyber activities and objectives. It provides an intuitive way to view the five functions, 23 categories, and 108 subcategories of the framework in one easy-to-understand chart. SMART PHOENIX also includes informative references for each subcategory so you can easily find more detailed information about each specific topic. With SMART PHOENIX, you'll be able to quickly identify functions and categories to create a customized plan tailored to meet your organization's needs. You won't have to worry about missing any important details or spending hours trying to make sense of complex technical documents - SMART PHOENIX will do all the hard work for you!
          </p>
          <p>
            Try SMART PHOENIX today and see how it can quickly visualize complex data in an easy-to-understand format so that you can make informed decisions!
          </p>
        </div>
        <div id="legend">
          <p>Legend: Subcategories</p>
          <table class="legend-table">
            <tr class="legend-row">
              <td class="legend-item"><span class="color-box not-modeled"></span>Unstudied Segment</td>
              <td class="legend-item"><span class="color-box weak-emergence"></span>Weak Emergence</td>
              <td class="legend-item"><span class="color-box strong-emergence"></span>Strong Emergence</td>
              <td class="legend-item"><span class="color-box positive-emergence"></span>Positive Emergence</td>
              <td class="legend-item"><span class="color-box negative-emergence"></span>Negative Emergence</td>
            </tr>
          </table>
        </div>
        <div
          className="TableNIST"
          style={{ background: "#282c34", msOverflowX: "scroll" }}
        >
          scroll vertically 🡻; and<br/>
          scroll horizontally 🡺
          <Table
            className="iui-root"
            data-iui-theme="dark"
            columns={columns}
            style={{ background: "#282c34", msOverflowX: "scroll" }}
            data={dataT}
            isSelectable
            isSelectableemptyFilteredTableContent="No results found. Clear or try another filter."
            emptyTableContent="No data."
            onSelect={handleSelect}
          />
        </div>
      </header>
    </div>
  );

}

export default App;

